export const environment = {
  production: true,
    currentEnv: 'ussgb',
    logoUniv: 'assets/img/universite/ussgb.png',
    adresseUniv : 'Quartier du fleuve, Rue : 310, Porte : 238 Prés du marché Dibida',
    contactUniv : ' (+223) 20 22 08 65',
    emailUniv : 'ussgb@yahoo.fr',
    bpUniv : '2575-BAMAKO',
    _http : 'https://'
};
