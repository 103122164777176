<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Informations</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Informations</li>
            </ul>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6" *ngFor="let partage of partages; let i=index;">
                        <div class="single-blog-post-item">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img [src]="'http://' + partage.fichier" alt="image" *ngIf="partage.fichier">
                                    <img src="assets/img/blog/img1.jpg" alt="image" *ngIf="!partage.fichier">
                                </a>
                                <div class="date">
                                    <span>{{partage.dateDebut}} - {{partage.dateDebut}}</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">{{partage.titre}}</span>
                                <h3><a routerLink="/blog-details">{{partage.description}}</a></h3>
                                <a routerLink="/blog-details" class="details-btn">{{partage.destinateur}}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post-item">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Adjust</span>
                                <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                                <a routerLink="/blog-details" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post-item">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                                <div class="date">
                                    <span>January 29, 2020</span>
                                </div>
                            </div>

                            <div class="post-content">
                                <span class="category">Coronavirus</span>
                                <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                                <a routerLink="/blog-details" class="details-btn">Read Story</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/blog-two" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-two" class="page-numbers">2</a>
                            <a routerLink="/blog-two" class="page-numbers">3</a>
                            <a routerLink="/blog-two" class="page-numbers">4</a>
                            <a routerLink="/blog-two" class="page-numbers">5</a>
                            <a routerLink="/blog-two" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Rechercher : </span>
                                <input type="search" class="search-field" placeholder="Rechercher...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_qovid_posts_thumb">
                        <h3 class="widget-title">Articles populaires</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Global cases top 300,000, John Hopkins University</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Global coronavirus cases top 300,000 as 13,000 deaths recorded</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">India starts 14-hour curfew to curb coronavirus spread</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Qovid Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Qovid <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>

                        <ul>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
