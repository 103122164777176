import { Injectable } from '@angular/core';
import {BASE_URL} from "../util/REST_URL";
import {HttpClient, HttpParams} from "@angular/common/http";


const filiereUrl = BASE_URL+'/filieres';
const optionUrl = BASE_URL+'/options';
@Injectable({
  providedIn: 'root'
})
export class FilieresOptionsService {

    constructor(private httpClient: HttpClient) { }


    public getStructureFilieres(id) {
        let param = new HttpParams();
        param = param.append("id_structure", id);
        return this.httpClient.get<any>(`${filiereUrl}`, {params: param});
    }

    public getStructure_Options(id){
        return this.httpClient.get<any>(`${optionUrl}/structure/${id}`);
    }

    // public getAllFiliere() {
    //     return this.httpClient.get(baseUrl);
    // }
    // public getFilieres(id) {
    //     return this.httpClient.get(`${baseUrl}/${id}`);
    // }
    //
    //
    // public getOpions(candidat_id){
    //     return this.httpClient.get(`${baseUrl}/optionChoose/${candidat_id}`);
    // }
    // public GetStructureFilieres(parameterBody) {
    //     let param = new HttpParams();
    //     if (Util_fonction.checkIfNoTEmpty(parameterBody.id_structure)){
    //         param = param.append("id_structure", parameterBody.id_structure);
    //     }
    //     if (Util_fonction.checkIfNoTEmpty(parameterBody.id_departement)){
    //         param = param.append("id_departement", parameterBody.id_departement);
    //     }
    //
    //     if (Util_fonction.checkIfNoTEmpty(parameterBody.id_specialiteCandidat)){
    //         param = param.append("id_specialiteCandidat", parameterBody.id_specialiteCandidat);
    //     }
    //
    //     if (Util_fonction.checkIfNoTEmpty(parameterBody.cursus)){
    //         param = param.append("cursus", parameterBody.cursus);
    //     }
    //     return this.httpClient.get<any>(`${baseUrl}`, {params: param});
    // }




}
