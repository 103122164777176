import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Util_fonction} from '../model/util_fonction';

const url = BASE_URL+"/forum/post";

@Injectable({
    providedIn: 'root'
})
export class DiscussionService {

    constructor(private httpClient: HttpClient) {
    }

    /* */
    public getPostWithCriteria(parameters: any) {
        let param = new HttpParams();
        if (Util_fonction.checkIfNoTEmpty(parameters.annee)) {
            param = param.append("annee", parameters.annee);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.date)) {
            param = param.append("date", parameters.date);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.idStructure)) {
            param = param.append("idStructure", parameters.idStructure);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.page)) {
            param = param.append("page", parameters.page);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.size)) {
            param = param.append("size", parameters.size);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.status)) {
            param = param.append("status", parameters.status);
        }
        return this.httpClient.get<any>(`${url}`, {params: param});
    }

    /* */
}
