import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/service/auth.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SpinnerService} from '../../shared/service/spinner.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

    form: FormGroup;

  constructor(
      protected router: Router,
      protected authService: AuthService,
      protected spinner: SpinnerService,
      protected fb: FormBuilder,
      ) {

  }

    ngOnInit(): void {
        this.initForm();
    }

  initForm() {
      this.form = this.fb.group({
          username: ['', Validators.required],
          password: ['', Validators.required],
      });
  }

    login() {
        console.log('form data', this.form.value);
        this.spinner.show('Connexion ....');
        this.authService.authenticate(this.form.value).subscribe(
            (response: any) => {
                console.log('login response', response);
                this.spinner.hide();
                this.authService.sigIn(response);
            },
            error => {
                console.log('login error', error);
                this.spinner.hide();
            },
        );
    }
    closeLoginModal() {
      this.authService.isOpen = false;
    }


}
