<app-header></app-header>
<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css"
    integrity="sha256-46r060N2LrChLLb5zowXQ72/iKKNiw/lAmygmHExk/o="
    crossorigin="anonymous"
/>
<div class="container col-sm-12 col-md-9 col-lg-9 shadow-lg mt-4 p-3">
    <div class="container text-center shadow-lg">
        <div class="section-title">
            <!-- <div>
                <h2 class="faq">FACULTES & INSTITUS</h2>
                <span class="sub-title">
                    Découvez nos filières et selon la faculte ou l'institue de
                    votre choix
                </span> -->
            <!--                <div>-->
            <!--                    <img src="{{logoUniv}}" style="height: auto; width: 155px" alt="">-->
            <!--                </div>-->
            <!-- </div> -->
        </div>
    </div>
    <h5 class="faq">FACULTES & INSTITUS</h5>
</div>

<div class="container">
    <div class="row">
        <div
            class="col-12 text-center p-3"
            *ngFor="let structure of ListDesStructures"
        >
            <div class="m-4 shadow-lg" style="border-radius: 10px">
                <h3>{{ structure.sigle }}</h3>
                <h6 class="">{{ structure.nom }}</h6>
                <hr />
                <div>
                    <img
                        src="{{ _http }}{{ structure.logo }}"
                        alt=""
                        style="height: auto; width: 250px"
                    />
                </div>

                <hr />
                <div class="p-3">
                    {{ structure.description }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div
        class="text-center"
        *ngFor="let structure of ListDesStructures"
        class="shadow-sm"
    >
        <!-- <div> -->
        <!-- <img
                        src="{{ _http }}{{ structure.logo }}"
                        alt=""
                        style="height: auto; width: 350px"
                    /> -->
        <!-- </div> -->
        <h3>{{ structure.sigle }}</h3>
        <h6 class="">{{ structure.nom }}</h6>
        <!-- <i class="fas fa-map-marker-alt" style="color: red"> </i
                ><span class="adresse"> {{ structure.adresse }} </span>
                <hr /> -->
        <!-- {{ structure.description }} -->
    </div>
</div>
