import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

   private loading = new BehaviorSubject<boolean>(false);

   public message$ = new BehaviorSubject<string>(null);

   public readonly loading$ = this.loading.asObservable();

  constructor() {}

  show(msg?) {
    this.loading.next(true);
    this.message$.next(msg);
  }

  hide() {
    this.loading.next(false);
    this.message$.next(null);
  }

}
