<app-header></app-header>

<div class="home-slides owl-carousel owl-theme">

    <div class="main-banner banner-bg1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                        <h1>Un nouveau schéma d'amélioration de la vie étudiante</h1>
                        <p>Le premier soucis des responsables de l’USSGB est de rehausser les
                            niveaux intellectuels de ses agents.</p>

<!--                        <div class="btn-box">-->
<!--                            <a routerLink="/about" class="default-btn"><i class="flaticon-open-book"></i> Lire la suite</a>-->

<!--                        </div>-->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/home/diplomes.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg2">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                        <h1></h1>
                        <p>Notre université pluridisciplinaire, rassemble les forces de
                            l’enseignement supérieur public de Bamako.</p>

<!--                        <div class="btn-box">-->
<!--                            <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> Lire la suite </a>-->
<!--                        </div>-->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/home/etudiants.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg3">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                        <h1></h1>
                        <p></p>

<!--                        <div class="btn-box">-->
<!--                            <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> Lire la suite </a>-->
<!--                        </div>-->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/home/IMG_20190722_092233_1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg3">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title"></span>
                        <h1>Imaginer collectivement l'USSGB de demain</h1>
                        <p>Pour une ambition commune vers une orientation coconstruite à
                            dimension académique.</p>

<!--                        <div class="btn-box">-->
<!--                            <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> lire la suite </a>-->
<!--                        </div>-->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/home/formationspro.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
<!--                    <img src="assets/img/universite/groupe-etude-du-peuple-africain.jpg" alt="image">-->
                    <img src="{{_http}}{{homeImage}}" style="height: auto; width: 100%" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>NOUS</h3>
                    <span class="sub-title">A propos de l'U.S.S.G.B</span>
                    <h2>Université des Sciences Sociales et de Gestion de Bamako</h2>

                    <div style="height: 30%; overflow: -moz-scrollbars-vertical">
                        <div class="panel-body" [innerHTML]="homeAbout"></div>

                    </div>
<!--                    <p>L’Université des Sciences Sociales et de Gestion de Bamako (USSGB) est un établissement Public à caractère scientifique, technologique et culturel situé à Bamako (Mali). Elle a été créée par l’Ordonnance N°2011-021/PRM du 28 septembre 2011</p>-->
<!--                    <p>Le 28 septembre 2011, le gouvernement malien a adopté en conseil des ministres quatre projets d'ordonnance portant création des « Universités de Bamako » en remplacement de l'université de Bamako dont les effectifs étaient devenus trop importants1.-->

<!--                        La création de l'université des sciences sociales et de gestion de Bamako a été adoptée par l'Assemblée nationale malienne le 8 décembre 2011</p>-->
<!--                    <blockquote>-->
<!--                        <p>L'éducation est l'arme la plus puissante pour changer le monde</p>-->
<!--                    </blockquote>-->
<!--                    <p>Since then, this strain has been diagnosed in several U.S. residents. The CDC have advised that it is likely to spread to more people. COVID-19 has started causing disruption in at least 25 other countries.</p>-->

<!--                    <a routerLink="/about" class="default-btn"><i class="flaticon-open-book"></i>Contactez nous</a>-->
                </div>
            </div>
        </div>
    </div>
</section>


<!--<section class="spread-virus-area pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Spreads Virus</span>-->
<!--            <h2>How Covid-19 Spreads</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-4 col-sm-6 col-md-6">-->
<!--                <div class="single-spread-virus-box">-->
<!--                    <img src="assets/img/spread-virus/img1.jpg" alt="image">-->
<!--                    <h3>Person to Person</h3>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>-->
<!--                    <a routerLink="/about-one" class="read-more-btn">Read More</a>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-4 col-sm-6 col-md-6">-->
<!--                <div class="single-spread-virus-box">-->
<!--                    <img src="assets/img/spread-virus/img2.jpg" alt="image">-->
<!--                    <h3>Infected Person Coughs</h3>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>-->
<!--                    <a routerLink="/about-one" class="read-more-btn">Read More</a>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">-->
<!--                <div class="single-spread-virus-box">-->
<!--                    <img src="assets/img/spread-virus/img3.jpg" alt="image">-->
<!--                    <h3>Close Contact Another</h3>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>-->
<!--                    <a routerLink="/about-one" class="read-more-btn">Read More</a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<!--<section class="symptoms-area pt-100">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="symptoms-image">-->
<!--                    <img src="assets/img/symptoms-img.png" alt="image">-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="symptoms-content">-->
<!--                    <span class="sub-title">Symptoms</span>-->
<!--                    <h2>Coronavirus Symptoms</h2>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>-->

<!--                    <ul>-->
<!--                        <li><span><i class='flaticon-tick'></i> Cough</span></li>-->
<!--                        <li><span><i class='flaticon-tick'></i> Fever</span></li>-->
<!--                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>-->
<!--                        <li><span><i class='flaticon-tick'></i> Headache</span></li>-->
<!--                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>-->
<!--                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->


<section class="funfacts-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="card-body">
<!--        <div class="panel-body" [innerHTML]="information.contenu"></div>-->
    </div>
</section>
<section class="funfacts-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <h2>Nos réalisations</h2>
        </div>

        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <img src="assets/img/universite/univPerf.jpeg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let state of Statistiques">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    .
                                </div>
                                <h3 class="odometer">{{state.contenu}}</h3>
                                <p>{{state.titre}}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>

</section>


<!--<section class="faq-area ptb-100 bg-f9f9f9">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Faq's</span>-->
<!--            <h2>Frequently Asked & Questions</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="faq-image faq-bg1">-->
<!--                    <img src="assets/img/faq-img.jpg" alt="image">-->

<!--                    <a href="https://www.youtube.com/watch?v=BtN-goy9VOY" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">-->
<!--                    <div class="card">-->
<!--                        <div class="card-header" role="tab" id="headingOne">-->
<!--                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">-->
<!--                                What is the source of the virus?-->
<!--                                <i class="flaticon-add"></i>-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">-->
<!--                            <div class="card-body">-->
<!--                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="card">-->
<!--                        <div class="card-header" role="tab" id="headingTwo">-->
<!--                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">-->
<!--                                How does the virus spread?-->
<!--                                <i class="flaticon-add"></i>-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">-->
<!--                            <div class="card-body">-->
<!--                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="card">-->
<!--                        <div class="card-header" role="tab" id="headingThree">-->
<!--                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
<!--                                Who has had COVID-19 spread the illness to others?-->
<!--                                <i class="flaticon-add"></i>-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">-->
<!--                            <div class="card-body">-->
<!--                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="card">-->
<!--                        <div class="card-header" role="tab" id="headingFour">-->
<!--                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">-->
<!--                                Will warm weather stop the outbreak of COVID-19?-->
<!--                                <i class="flaticon-add"></i>-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">-->
<!--                            <div class="card-body">-->
<!--                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="card">-->
<!--                        <div class="card-header" role="tab" id="headingFive">-->
<!--                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">-->
<!--                                What is community spread?-->
<!--                                <i class="flaticon-add"></i>-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">-->
<!--                            <div class="card-body">-->
<!--                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="faq-shape1"><img src="assets/img/faq-shape1.png" alt="image"></div>-->
<!--    <div class="faq-shape2"><img src="assets/img/faq-shape2.png" alt="image"></div>-->
<!--</section>-->


<!--<section class="emergency-contact-area">-->
<!--    <div class="container">-->
<!--        <div class="emergency-contact-inner">-->
<!--            <div class="row align-items-center">-->
<!--                <div class="col-lg-8 col-md-12">-->
<!--                    <div class="btn-box">-->
<!--                        <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>-->
<!--                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-4 col-md-12">-->
<!--                    <div class="contact-cta">-->
<!--                        <a href="tel:+44587154756">-->
<!--                            <i class="flaticon-call"></i>-->
<!--                            <span>Emergency Contact</span>-->
<!--                            +44 587 154756-->
<!--                        </a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->


<!--<section class="cta-area">-->
<!--    <div class="container">-->
<!--        <div class="cta-inner-area">-->
<!--            <div class="row align-items-center">-->
<!--                <div class="col-lg-8 col-md-12">-->
<!--                    <div class="row align-items-center">-->
<!--                        <div class="col-lg-5 col-md-5">-->
<!--                            <img src="assets/img/man.png" alt="image">-->
<!--                        </div>-->

<!--                        <div class="col-lg-7 col-md-7">-->
<!--                            <h3>We Operate 24h a day - Every Day!</h3>-->
<!--                            <p>Call us if you have any problems.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-4 col-md-12">-->
<!--                    <div class="cta-btn">-->
<!--                        <a href="tel:+44587154756">-->
<!--                            <i class="flaticon-call"></i>-->
<!--                            <span>Emergency Contact</span>-->
<!--                            +44 587 154756-->
<!--                        </a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->


<!--<section class="doctors-area pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Doctors</span>-->
<!--            <h2>Meet Specialist Doctors</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-4 col-sm-6 col-md-6">-->
<!--                <div class="single-doctors-box">-->
<!--                    <div class="image">-->
<!--                        <img src="assets/img/doctors/img1.jpg" alt="image">-->

<!--                        <ul class="social">-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                        </ul>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <h3>Dr. Addison Smith</h3>-->
<!--                        <span>Nephrologists</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-4 col-sm-6 col-md-6">-->
<!--                <div class="single-doctors-box">-->
<!--                    <div class="image">-->
<!--                        <img src="assets/img/doctors/img2.jpg" alt="image">-->

<!--                        <ul class="social">-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                        </ul>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <h3>Dr. Sarah Taylor</h3>-->
<!--                        <span>Infectious</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">-->
<!--                <div class="single-doctors-box">-->
<!--                    <div class="image">-->
<!--                        <img src="assets/img/doctors/img3.jpg" alt="image">-->

<!--                        <ul class="social">-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                        </ul>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <h3>Dr. Aiken Ward</h3>-->
<!--                        <span>Cardiologists</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->


<!--<section class="how-to-protect-area ptb-100">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Prevention</span>-->
<!--            <h2>How to Protect Yourself</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-5 col-md-12">-->
<!--                <div class="how-to-protect-content">-->
<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-hand-wash"></i>-->
<!--                            </div>-->
<!--                            <h3>Wash Your Hands</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-people"></i>-->
<!--                            </div>-->
<!--                            <h3>Avoid Close Contact</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-home-plan"></i>-->
<!--                            </div>-->
<!--                            <h3>Stay at Home</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-face-detection"></i>-->
<!--                            </div>-->
<!--                            <h3>Don’t Touch Your Face</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-mask"></i>-->
<!--                            </div>-->
<!--                            <h3>Wear a Face Mask</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="content">-->
<!--                        <div class="text">-->
<!--                            <div class="icon">-->
<!--                                <i class="flaticon-network"></i>-->
<!--                            </div>-->
<!--                            <h3>Avoid Crowded Places</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-7 col-md-12">-->
<!--                <div class="how-to-protect-image">-->
<!--                    <img src="assets/img/how-to-protect/img1.jpg" class="main-image main-image1" alt="image">-->
<!--                    <img src="assets/img/how-to-protect/img2.jpg" class="main-image main-image2" alt="image">-->
<!--                    <img src="assets/img/how-to-protect/img3.jpg" class="main-image main-image3" alt="image">-->
<!--                    <img src="assets/img/how-to-protect/img4.jpg" class="main-image main-image4" alt="image">-->
<!--                    <img src="assets/img/how-to-protect/img5.jpg" class="main-image main-image5" alt="image">-->
<!--                    <img src="assets/img/how-to-protect/img6.jpg" class="main-image main-image6" alt="image">-->

<!--                    <div class="how-to-protect-shape">-->
<!--                        <img src="assets/img/how-to-protect/shape1.png" alt="image">-->
<!--                        <img src="assets/img/how-to-protect/shape2.png" alt="image">-->
<!--                        <img src="assets/img/how-to-protect/shape3.png" alt="image">-->
<!--                        <img src="assets/img/how-to-protect/shape4.png" alt="image">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->


<!--<section class="products-area pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Shop</span>-->
<!--            <h2>Shop Emergency Medical Products</h2>-->
<!--        </div>-->

<!--        <div class="shorting-menu">-->
<!--            <button class="filter" data-filter="all">All</button>-->
<!--            <button class="filter" data-filter=".medicalMask">Medical Mask</button>-->
<!--            <button class="filter" data-filter=".antiseptics">Antiseptics</button>-->
<!--            <button class="filter" data-filter=".medicalGloves">Medical Gloves</button>-->
<!--            <button class="filter" data-filter=".drugs">Drugs</button>-->
<!--        </div>-->

<!--        <div class="shorting">-->
<!--            <div class="row">-->
<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix medicalMask">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img1.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Medical Mask</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$18</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix antiseptics">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img2.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Antiseptics</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$20</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix medicalGloves">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img3.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Medical Gloves</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$15</span>-->
<!--                                <span class="old">$20</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix drugs">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img4.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Drugs</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$10</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix antiseptics">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img5.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Asprin</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$18</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix antiseptics">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="#">-->
<!--                                <img src="assets/img/products/img6.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Sanitizer</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$18</span>-->
<!--                                <span class="old">$20</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix medicalMask">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img7.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Black Mask</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$25</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-3 col-md-6 col-sm-6 mix antiseptics">-->
<!--                    <div class="single-products-box">-->
<!--                        <div class="products-image">-->
<!--                            <a routerLink="/products-details">-->
<!--                                <img src="assets/img/products/img8.jpg" alt="image">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                        <div class="products-content">-->
<!--                            <h3><a routerLink="/products-details">Hand Sanitizer</a></h3>-->
<!--                            <div class="price">-->
<!--                                <span class="new">$8</span>-->
<!--                            </div>-->
<!--                            <a routerLink="/cart" class="add-to-cart-btn"><i class="flaticon-shopping-cart"></i> Add to cart</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<!--<section class="blog-area bg-f9f9f9 pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <span class="sub-title">Blog</span>-->
<!--            <h2>Worldwide Latest News</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="single-blog-post">-->
<!--                    <div class="post-image">-->
<!--                        <a routerLink="/blog-details">-->
<!--                            <img src="assets/img/blog/img1.jpg" alt="image">-->
<!--                        </a>-->
<!--                    </div>-->

<!--                    <div class="post-content">-->
<!--                        <span class="date"><i class="flaticon-event"></i> 15 April, 2020</span>-->
<!--                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="small-blog-post">-->
<!--                    <div class="single-small-blog-post">-->
<!--                        <div class="d-flex align-items-center">-->
<!--                            <div class="post-image">-->
<!--                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img1.jpg" alt="image"></a>-->
<!--                            </div>-->

<!--                            <div class="post-content">-->
<!--                                <span class="date"><i class="flaticon-event"></i> 14 April, 2020</span>-->
<!--                                <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="single-small-blog-post">-->
<!--                        <div class="d-flex align-items-center">-->
<!--                            <div class="post-image">-->
<!--                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img2.jpg" alt="image"></a>-->
<!--                            </div>-->

<!--                            <div class="post-content">-->
<!--                                <span class="date"><i class="flaticon-event"></i> 13 April, 2020</span>-->
<!--                                <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="single-small-blog-post">-->
<!--                        <div class="d-flex align-items-center">-->
<!--                            <div class="post-image">-->
<!--                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img3.jpg" alt="image"></a>-->
<!--                            </div>-->

<!--                            <div class="post-content">-->
<!--                                <span class="date"><i class="flaticon-event"></i> 12 April, 2020</span>-->
<!--                                <h3><a routerLink="/blog-details">New Africa coronavirus crisis looms with internal spread</a></h3>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
