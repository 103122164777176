import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    adresseUniv = environment.adresseUniv;
    emailUniv = environment.emailUniv;
    contactUniv = environment.contactUniv;
    bpUniv = environment.bpUniv;
  constructor() { }

  ngOnInit(): void {
  }

}
