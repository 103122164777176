<app-header></app-header>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk=" crossorigin="anonymous" />




<div class="container text-center shadow-lg mt-4" >
    <div class="section-title">
        <div>
            <h2>Informations</h2>
            <span class="sub-title">
                Retrouver toutes les informations nécessaires sur nous
            </span>
        </div>
    </div>
</div>


<div class="container">

    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4></h4>
                </div>
                <div class="card-body">
                    <div *ngFor="let information of informations">
                        <div class="panel-body" [innerHTML]="information.contenu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4>Frais</h4>
                </div>
                <div class="card-body">
                    <div class="table-responsive overflow-auto" id="proTeamScroll" tabindex="2" style="height: 400px; overflow: hidden; outline: none;">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>Structure</th>
                                <th>Type de profil</th>
                                <th>Type de frais</th>
                                <th>Montant</th>
<!--                                <th>Filière</th>-->


                            </tr>
                            </thead>
                            <tbody *ngFor="let frais of fraisList">
                            <tr>
                                <td>
                                    <p>{{frais.structure.sigle}}</p>
                                </td>

                                <td class="table-img">
                                    <p>{{frais.typeCandidat}}</p>
                                </td>

                                <td>
                                    <h6 class="mb-0 font-13"></h6>
                                    <p class="m-0 font-12">
                                        <span class="col-green font-weight-bold"> {{frais.typeFrais}}</span>
                                    </p>
                                </td>

                                <td>
                                    <p>{{frais.frais}}</p>
                                </td>

<!--                                <td class="text-truncate">-->
<!--                                   <p>{{frais.structure.filiere}}</p>-->
<!--                                </td>-->




                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
