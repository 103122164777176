import { Component, OnInit } from "@angular/core";
import { PagesInformationDataService } from "../../shared/service/pages-information-data.service";
import { Util_fonction } from "../../shared/model/util_fonction";
import { StructuresService } from "../../shared/service/structures.service";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    _spinner: boolean = false;
    _http = environment._http;
    constructor(
        private pageInfoService: PagesInformationDataService,
        private structureService: StructuresService
    ) {}

    ngOnInit(): void {
        this.GetStructures();
        this.GetHomeImage();
        this.GetHomeAbaout();
        this.GetHomePageStatistique();
    }
    GetStructures() {
        this.structureService.getStuctures().subscribe((res) => {
            const rectoratData = res.find((s) => s.sigle === "RECTORAT");
            this.GetPageInformations(rectoratData);
        });
    }

    GetPageInformations(rectoratData) {
        this._spinner = true;
        this.pageInfoService
            .getPageInformations("personaAdministra")
            .subscribe((getResponse) => {
                const Personas = getResponse.content.filter(
                    (element) => +element.structure.id === +rectoratData.id
                );
                this._spinner = false;
            });
    }

    homeImage;
    GetHomeImage() {
        this.pageInfoService
            .getPageInformations("homeImage")
            .subscribe((infoServiceResponse) => {
                this.homeImage = infoServiceResponse.content[0].contenu;
            });
    }

    homeAbout;
    GetHomeAbaout() {
        this.pageInfoService
            .getPageInformations("home")
            .subscribe((homeAboutResponse) => {
                this.homeAbout = homeAboutResponse.content[0].contenu;
            });
    }

    Statistiques;
    GetHomePageStatistique() {
        this.pageInfoService.getPageInformations("statistiques").subscribe(
            (getResponse) => {
                console.log(this.Statistiques);

                this.Statistiques = getResponse.content;
            },
            (error) => {
                Util_fonction.AlertMessage(
                    error.error.status,
                    error.error.message
                );
            }
        );
    }
}
