<app-header></app-header>

<div class="container text-center shadow-lg mt-4" >
    <div class="section-title">
        <div>
            <h2 class="campus">CAMPUS</h2>
            <span class="sub-title">
                    Environnement idéal pour bien étudier
            </span>
        </div>
    </div>
</div>


<div class="container shadow-lg mt-3 p-3">
    <div *ngFor="let information of informations">
        <div class="panel-body" [innerHTML]="information.contenu"></div>
    </div>
</div>

