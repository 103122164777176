import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Util_fonction} from '../model/util_fonction';

const url = BASE_URL+"/forum/comment";

@Injectable({
    providedIn: 'root'
})
export class CommentService {

    constructor(private httpClient: HttpClient) {
    }

    /* */
    public addComment(parameters: any) {
        const body = {
            content: parameters.content,
            date: parameters.date,
            post: {
                id: parameters.post
            },
            user: {
                id: parameters.user
            }
        };

        return this.httpClient.post<any>(url, body);
    }



    /* */
}
