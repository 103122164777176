<app-header></app-header>

<div class="container text-center shadow-lg mt-4 background-image-container">
    <div class="section-title">
        <div>
            <h2 class="actu">Bibliothèque</h2>
        </div>
    </div>
</div>

<div
    class="container mt-4 p-3"
    style="border: 1px solid #000; position: sticky"
>
    <button class="btn btn-sm" (click)="handleHide = !handleHide">
        <i class="fa fa-chevron-down"></i>
    </button>
    <h6 class="text-center">Filtre de recherche</h6>
    <hr />
    <div [hidden]="handleHide">
        <form [formGroup]="SearchForm" (ngSubmit)="getOuvrage()">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label for="search"
                            >Filtrer par structure
                            <span class="text-danger">*</span>:</label
                        >
                        <select
                            formControlName="structure"
                            class="form-control"
                            style="border: 1px solid #000"
                            name="structures"
                            id="structures"
                            (change)="structureSelected($event)"
                        >
                            <option
                                value="{{ structure.id }}"
                                *ngFor="let structure of ListDesStructures"
                            >
                                {{ structure.sigle }}
                            </option>
                        </select>
                        <p
                            *ngIf="SearchForm.controls.structure.invalid"
                            class="text-danger"
                        >
                            Ce champs est obligatoire
                        </p>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label for="search"
                            >Filtrer par type
                            <span class="text-danger">*</span>:</label
                        >
                        <select
                            id="search"
                            class="form-control custom_Input"
                            formControlName="searchType"
                        >
                            <option value="LIVRE">LIVRE</option>
                            <option value="ARTICLE">ARTICLE</option>
                            <option value="MEMOIRE">MEMOIRE</option>
                        </select>
                        <p
                            *ngIf="SearchForm.controls.searchType.invalid"
                            class="text-danger"
                        >
                            Ce champs est obligatoire
                        </p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="titre"
                            >Titre <span class="text-danger"></span
                        ></label>
                        <input
                            type="text"
                            class="form-control custom_Input"
                            id="titre"
                            formControlName="titre"
                        />
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label for="Genre"
                            >Genre <span class="text-danger"></span
                        ></label>
                        <select
                            id="Genre"
                            class="form-control custom_Input"
                            formControlName="id_genre"
                        >
                            <option value=""></option>
                            <option
                                value="{{ genre.id }}"
                                *ngFor="let genre of GenresList"
                            >
                                {{ genre.nom }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label for="Auteur"
                            >Auteur <span class="text-danger"></span
                        ></label>
                        <select
                            id="Auteur"
                            class="form-control custom_Input"
                            formControlName="id_auteur"
                        >
                            <option value=""></option>
                            <option
                                value="{{ auteurs.id }}"
                                *ngFor="let auteurs of AuteursList"
                            >
                                {{ auteurs.nom }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label for="Editeur"
                            >Editeur <span class="text-danger"></span
                        ></label>
                        <select
                            id="Editeur"
                            class="form-control custom_Input"
                            formControlName="id_editeur"
                        >
                            <option value=""></option>
                            <option
                                value="{{ editeurs.id }}"
                                *ngFor="let editeurs of EditeursList"
                            >
                                {{ editeurs.nom }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="text-center mt-1">
                <span *ngIf="search_spinner" style="text-align: center">
                    <img
                        src="../../../../assets/gif/spinner.gif"
                        width="65"
                        alt=""
                    />
                </span>

                <button
                    mat-raised-button
                    color="accent"
                    [disabled]="SearchForm.invalid"
                    *ngIf="!search_spinner"
                >
                    <i class="fa fa-search"></i>
                    valider la recherche
                </button>
            </div>
        </form>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-4 p-2" *ngFor="let livre of Livres">
            <div class="shadow-lg m-3" style="border-radius: 10px">
                <img
                    src="{{ this._http }}{{ livre.image }}"
                    style="height: auto; width: max-width"
                    alt=""
                />
                <div>
                    <button
                        (click)="showDetail(livre)"
                        class="btn btn-inline-bloc btn-outline-dark"
                        style="width: 100%"
                    >
                        <i class="fa fa-eye"></i>
                        voir les details
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL-->
<div
    class="modal fade"
    id="DetailModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4
                    class="modal-title font-weight-bold"
                    id="exampleModalLabel"
                    *ngIf="!!BookDatas"
                >
                    {{ BookDatas.titre }}
                </h4>
                <button type="button" mat-button data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="!!BookDatas">
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="container bg-white p-2">
                                            <div class="mt-2">
                                                <div
                                                    class="container col-4 p-2 mb-3"
                                                >
                                                    <img
                                                        src="{{ _http }}{{
                                                            BookDatas.image
                                                        }}"
                                                        width="150"
                                                        height="210"
                                                        alt=""
                                                    />
                                                </div>

                                                <div class="mt-2 mb-2">
                                                    <h3
                                                        class="text-center mt-2"
                                                    >
                                                        {{ BookDatas.titre }}
                                                    </h3>
                                                </div>

                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong>Editeur :</strong>
                                                    {{ BookDatas.editeur.nom }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong>Auteurs : </strong>
                                                    <span
                                                        class="badge badge-light ml-2"
                                                        *ngFor="
                                                            let author of BookDatas.auteurs
                                                        "
                                                        >{{ author.nom }}</span
                                                    >
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong>Genre:</strong>
                                                    {{
                                                        BookDatas.genreLivre.nom
                                                    }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong>ISBN/ISS:</strong>
                                                    {{ BookDatas.isbnIssn }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong>Langue: </strong>
                                                    {{ BookDatas.langue }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong
                                                        >Disponibilité :
                                                    </strong>
                                                    {{
                                                        BookDatas.disponibilite
                                                    }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong
                                                        >Nombre d'exemplaire
                                                        Total :
                                                    </strong>
                                                    {{
                                                        BookDatas.nbreExemplaireTotal
                                                    }}
                                                </div>
                                                <div
                                                    style="
                                                        border-bottom: 1px solid
                                                            #cccccc;
                                                    "
                                                >
                                                    <strong
                                                        >Nombre disponible :
                                                    </strong>
                                                    {{
                                                        BookDatas.nbreExemplaireDisponible
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="container bg-white p-2">
                                            <div class="container col-4 p-2">
                                                <img
                                                    src="{{ _http }}{{
                                                        BookDatas.image
                                                    }}"
                                                    width="250"
                                                    height="310"
                                                    alt=""
                                                />
                                            </div>

                                            <h2 class="text-center">
                                                Description:
                                            </h2>

                                            <div class="mt-2">
                                                {{ BookDatas.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                >
                    Annuler
                </button>
            </div>
        </div>
    </div>
</div>
