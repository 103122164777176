import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PagesInformationDataService } from "../../../shared/service/pages-information-data.service";
import { StructuresService } from "src/app/components/shared/service/structures.service";
import { environment } from "src/environments/environment";
declare var $: any;

@Component({
    selector: "app-anonces",
    templateUrl: "./anonces.component.html",
    styleUrls: ["./anonces.component.scss"],
})
export class AnoncesComponent implements OnInit {
    _http = environment._http;
    show: boolean;
    thumbs: any;
    slides$ = new BehaviorSubject<string[]>([""]);
    ListDesStructures: any;
    constructor(
        private pageInfoService: PagesInformationDataService,
        private cd: ChangeDetectorRef,
        private ngZone: NgZone,
        private structureService: StructuresService
    ) {}
    ngOnInit() {
        this.GetActuAndAnnoces();
        this.GetStructures();
    }

    InfoData;
    InfoDataTemp;
    GetActuAndAnnoces(idStructure = null) {
        let pathS = "type=actualite";
        // if (!!idStructure) pathS += " AND structure__id=" + idStructure;
        this.pageInfoService
            .getPageActuInformations(pathS)
            .subscribe((infoServiceResponse) => {
                this.InfoData = infoServiceResponse.content;
                this.InfoDataTemp = infoServiceResponse.content;
            });
    }

    GetStructures() {
        this.structureService.getStuctures().subscribe((res) => {
            this.ListDesStructures = res; //.filter((s) => s.type !== "RECTORAT");
        });
    }

    selectedStructure;
    structureSelected(event) {
        console.log(event.target.value);
        if (event.target.value === "tout") {
            this.InfoData = this.InfoDataTemp.filter(
                (item) => item.structure.id !== "tout"
            );
            return;
        }

        this.selectedStructure = this.ListDesStructures.filter(
            (item) => +item.id === +event.target.value
        );
        this.InfoData = this.InfoDataTemp.filter(
            (item) => +item.structure.id === +event.target.value
        );
        // this.GetActuAndAnnoces(event.target.value);
        console.log(this.selectedStructure);
    }

    Details;
    showDetail(details) {
        this.Details = details;
        $("#AnnonceModal").modal({
            backdrop: "static",
            keyboard: false,
        });
        $("#AnnonceModal").appendTo("body");
    }

    currentContent;
    showContent(info) {
        this.currentContent = info;
    }

    getSlides() {
        this.slides$.next(
            Array.from({ length: 600 }).map((el, index) => `Slide ${index + 1}`)
        );
    }

    thumbsSwiper: any;
    setThumbsSwiper(swiper) {
        this.thumbsSwiper = swiper;
    }
    controlledSwiper: any;
    setControlledSwiper(swiper) {
        this.controlledSwiper = swiper;
    }

    indexNumber = 1;
    exampleConfig = { slidesPerView: 3 };
    slidesPerView: number = 4;
    pagination: any = false;

    slides2 = ["slide 1", "slide 2", "slide 3"];
    replaceSlides() {
        this.slides2 = ["foo", "bar"];
    }

    togglePagination() {
        if (!this.pagination) {
            this.pagination = { type: "fraction" };
        } else {
            this.pagination = false;
        }
    }

    navigation = false;
    toggleNavigation() {
        this.navigation = !this.navigation;
    }

    scrollbar: any = false;
    toggleScrollbar() {
        if (!this.scrollbar) {
            this.scrollbar = { draggable: true };
        } else {
            this.scrollbar = false;
        }
    }
    breakpoints = {
        640: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 4, spaceBetween: 40 },
        1024: { slidesPerView: 4, spaceBetween: 50 },
    };

    slides = Array.from({ length: 5 }).map((el, index) => `Slide ${index + 1}`);
    virtualSlides = Array.from({ length: 600 }).map(
        (el, index) => `Slide ${index + 1}`
    );

    log(log: string) {
        // console.log(string);
    }

    breakPointsToggle: boolean;
    breakpointChange() {
        this.breakPointsToggle = !this.breakPointsToggle;
        this.breakpoints = {
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 40 },
            1024: {
                slidesPerView: this.breakPointsToggle ? 7 : 5,
                spaceBetween: 50,
            },
        };
    }

    slidesEx = ["first", "second"];

    onSlideChange(swiper: any) {
        if (swiper.isEnd) {
            // all swiper events are run outside of ngzone, so use ngzone.run or detectChanges to update the view.
            this.ngZone.run(() => {
                this.slidesEx = [
                    ...this.slidesEx,
                    `added ${this.slidesEx.length - 1}`,
                ];
            });
            console.log(this.slidesEx);
        }
    }
}
