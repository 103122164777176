import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { OuvragesService } from "src/app/components/shared/service/ouvrages.service";
import { environment } from "../../../../../environments/environment";
import { StructuresService } from "src/app/components/shared/service/structures.service";
import { Util_fonction } from "src/app/components/shared/model/util_fonction";
declare var $: any;

const _HTTP = environment._http;
@Component({
    selector: "app-bibliotheque",
    templateUrl: "./bibliotheque.component.html",
    styleUrls: ["./bibliotheque.component.scss"],
})
export class BibliothequeComponent implements OnInit {
    constructor(
        private formbuilder: FormBuilder,
        private ouvragesService: OuvragesService,
        private structureService: StructuresService
    ) {}

    _http = _HTTP;
    SearchForm: FormGroup;
    search_spinner = false;
    handleHide = false;
    ListBody = {
        type: null,
        titre: null,
        id_structure: null,
        id_user: null,
        id_auteur: null,
        id_editeur: null,
        id_genre: null,
    };
    user = JSON.parse(sessionStorage.getItem("user"));
    GenresList: any;
    AuteursList: any;
    EditeursList: any;
    allAuteurs: any;
    Livres: any;
    BookDatas: any;
    SelectedStructure;
    ngOnInit(): void {
        this.initForm();
        this.GetStructures();
        this.getGenres();
        this.getAuteurs();
        this.getEditeurs();
        // this.SelectedStructure = this.user.structure.id;
    }

    ListDesStructures;
    GetStructures() {
        this.structureService.getStuctures().subscribe((res) => {
            this.ListDesStructures = res; //.filter((s) => s.type !== "RECTORAT");
        });
    }

    getGenres() {
        this.ouvragesService.list_Genre().subscribe((list) => {
            this.GenresList = list;
            console.log(list);
        });
    }

    getAuteurs() {
        this.ouvragesService.list_auteurs().subscribe((listAuthor) => {
            this.AuteursList = listAuthor;
            for (let auth of listAuthor) {
                this.allAuteurs.push(auth.nom);
            }

            console.log(listAuthor);
            console.log(this.allAuteurs);
        });
    }

    getEditeurs() {
        this.ouvragesService.list_Editeur().subscribe((list) => {
            this.EditeursList = list;
        });
    }

    getOuvrage() {
        this.search_spinner = true;
        this.ListBody.id_structure = this.SelectedStructure; //this.user.structure.id;
        this.ListBody.type = this.SearchForm.controls.searchType.value;
        this.ListBody.titre = this.SearchForm.controls.titre.value;
        this.ListBody.id_auteur = this.SearchForm.controls.id_auteur.value;
        this.ListBody.id_editeur = this.SearchForm.controls.id_editeur.value;
        this.ListBody.id_genre = this.SearchForm.controls.id_genre.value;
        this.ouvragesService.GetAllOuvrage(this.ListBody).subscribe(
            (listLivres) => {
                // this.dataSource = new MatTableDataSource(listLivres.content);
                // this.dataSource.paginator = this.paginator;
                this.Livres = listLivres.content;
                this.search_spinner = false;

                if (this.Livres.length <= 0) {
                    return Util_fonction.AlertMessage(
                        "info",
                        "Pas de résulta correspondanr à la recherche!"
                    );
                }
            },
            (error) => {
                this.search_spinner = false;
            }
        );
    }

    showDetail(details) {
        this.BookDatas = details;
        $("#DetailModal").modal({
            backdrop: "static",
            keyboard: false,
        });
        $("#DetailModal").appendTo("body");
    }

    selectedStructure;
    structureSelected(event) {
        console.log(event.target.value);
        if (event.target.value === "tout") {
            return this.SearchForm.controls.structure.setValue(null);
        }

        this.SelectedStructure = event.target.value;
    }

    initForm() {
        this.SearchForm = this.formbuilder.group({
            structure: new FormControl(null, Validators.required),
            searchType: new FormControl(null, Validators.required),
            titre: new FormControl(null),
            id_auteur: new FormControl(null),
            id_editeur: new FormControl(null),
            id_genre: new FormControl(null),
        });
    }
}
