<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Universite</h2>
            <ul>
                <li><a routerLink="/">Acceuil</a></li>
                <li>Universite</li>
            </ul>
        </div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                    <img src="assets/img/about-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Covid-19</span>
                    <h2>About Coronavirus Disease</h2>
                    <p>In 2019, the Centers for Disease Control and Prevention (CDC) started monitoring the outbreak of a new coronavirus, SARS-CoV-2, which causes the respiratory illness now known as COVID-19. Authorities first identified the virus in Wuhan, China.</p>
                    <p>More than 78,191 people have contracted the virus in China. Health authorities have identified many other people with COVID-19 around the world, including in the United States. On January 31, 2020, the virus passed from one person to another in the U.S.</p>
                    <blockquote>
                        <p>The World Health Organization (WHO) have declared a public health emergency relating to COVID-19.</p>
                    </blockquote>
                    <p>Since then, this strain has been diagnosed in several U.S. residents. The CDC have advised that it is likely to spread to more people. COVID-19 has started causing disruption in at least 25 other countries.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <!--
                <div class="symptoms-image">

                    <img src="assets/img/symptoms-img.png" alt="image">

                </div>
            -->
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <h2>COVID-19 Coronavirus Outbreak</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <!--
                    <img src="assets/img/funfacts.jpg" alt="image">
                    -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <!--
                                    <img src="assets/img/icon1.png" alt="image">
                                    -->
                                </div>
                                <h3 class="odometer" data-count="187">00</h3>
                                <p>Total Country</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <!--
                                    <img src="assets/img/icon1.png" alt="image">
                                    -->
                                </div>
                                <h3 class="odometer" data-count="294110">00</h3>
                                <p>Confirmed Cases</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <!--
                                    <img src="assets/img/icon1.png" alt="image">
                                    -->
                                </div>
                                <h3 class="odometer" data-count="12944">00</h3>
                                <p>Deaths</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <!--
                                    <img src="assets/img/icon1.png" alt="image">
                                    -->
                                </div>
                                <h3 class="odometer" data-count="100345">00</h3>
                                <p>Recovered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="funfacts-info">
                    <p>Source: <a href="https://www.who.int/" target="_blank">WHO</a> on March 23, 2020</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">

                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">

                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">

                        <img src="assets/img/doctors/img3.jpg" alt="image">


                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-image faq-bg1">
                    <!--
                    <img src="assets/img/faq-img.jpg" alt="image">
                    -->

                    <a href="https://www.youtube.com/watch?v=BtN-goy9VOY" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                What is the source of the virus?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How does the virus spread?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Who has had COVID-19 spread the illness to others?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Will warm weather stop the outbreak of COVID-19?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                What is community spread?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="faq-shape1"><img src="assets/img/faq-shape1.png" alt="image"></div>
    <div class="faq-shape2"><img src="assets/img/faq-shape2.png" alt="image"></div>
    -->
</section>

<section class="spread-virus-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Spreads Virus</span>
            <h2>How Covid-19 Spreads</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <!--
                    <img src="assets/img/spread-virus/img1.jpg" alt="image">
                    -->
                    <h3>Person to Person</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <!--
                    <img src="assets/img/spread-virus/img2.jpg" alt="image">
                    -->
                    <h3>Infected Person Coughs</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-spread-virus-box">
                    <!--
                    <img src="assets/img/spread-virus/img3.jpg" alt="image">
                    -->
                    <h3>Close Contact Another</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>
        </div>
    </div>
</section>
