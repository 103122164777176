import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { UniversiteComponent } from './components/pages/universite/universite.component';
import { AboutTwoComponent } from './components/pages/about-two/about-two.component';
import { DoctorsComponent } from './components/pages/doctors/doctors.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { CampusComponent } from './components/pages/campus/campus.component';
import { OutbreakComponent } from './components/pages/outbreak/outbreak.component';
import { ProfilComponent } from './components/pages/profil/profil.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LiveTrackerComponent } from './components/pages/live-tracker/live-tracker.component';
import {InformationComponent} from './components/pages/information/information.component';
import {ForumComponent} from "./components/pages/forum/forum.component";
import {AnoncesComponent} from "./components/pages/_portail/anonces/anonces.component";
import {AboutComponent} from "./components/pages/_portail/about/about.component";
import {ContactComponent} from "./components/pages/_portail/contact/contact.component";
import {InfoVisiteurComponent} from "./components/pages/_portail/info-visiteur/info-visiteur.component";
import {FormationsComponent} from "./components/pages/_portail/formations/formations.component";
import {StructuresComponent} from "./components/pages/_portail/about/structures/structures.component";
import { BibliothequeComponent } from './components/pages/_portail/bibliotheque/bibliotheque.component';
//import {InscriptionsEvenementsComponent} from './components/pages/_visiteurs/inscriptions-evenements/inscriptions-evenements.component';
//import {ProfilageEtVerificationComponent} from './components/pages/_visiteurs/profilage-et-verification/profilage-et-verification.component';
//import {CandidatInfosComponent} from './components/pages/_visiteurs/_Formulaires_Inscriptions/candidat-infos/candidat-infos.component';
//import {InscriptionInfosComponent} from './components/pages/_visiteurs/_Formulaires_Inscriptions/inscription-infos/inscription-infos.component';

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "universite", component: UniversiteComponent },
    { path: "faq", component: FaqComponent },
    { path: "informations", component: InformationComponent },
    { path: "error", component: ErrorComponent },
    { path: "profil", component: ProfilComponent },
    { path: "home-two", component: HomeTwoComponent },
    { path: "home-three", component: HomeThreeComponent },
    { path: "home-four", component: HomeFourComponent },
    { path: "home-five", component: HomeFiveComponent },
    { path: "home-six", component: HomeSixComponent },
    { path: "about-two", component: AboutTwoComponent },
    { path: "doctors", component: DoctorsComponent },
    { path: "appointment", component: AppointmentComponent },
    { path: "outbreak", component: OutbreakComponent },
    { path: "checkout", component: CheckoutComponent },
    { path: "products-details", component: ProductsDetailsComponent },
    { path: "blog-one", component: BlogOneComponent },
    { path: "blog-two", component: BlogTwoComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "live-tracker", component: LiveTrackerComponent },
    { path: "coming-soon", component: ComingSoonComponent },
    { path: "about", component: AboutComponent },
    { path: "formations", component: FormationsComponent },
    { path: "forum", component: ForumComponent },
    { path: "campus", component: CampusComponent },
    { path: "annonces", component: AnoncesComponent },
    { path: "contact", component: ContactComponent },
    { path: "structure", component: StructuresComponent },
    { path: "infos-visiteurs", component: InfoVisiteurComponent },
    { path: "bibliotheque", component: BibliothequeComponent },

    //{path: 'Evenements_Inscription', component: InscriptionsEvenementsComponent},
    //{path: 'verification_et_profilage_du_candidat', component: ProfilageEtVerificationComponent},
    //{path: 'Infomation_du_Candidat', component: CandidatInfosComponent}, // pour moi
    // {path: 'formulaire_d_inscription_Candidat', component: InscriptionInfosComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
