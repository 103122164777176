<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Prevention</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Prevention</li>
            </ul>
        </div>
    </div>
</section>

<section class="how-to-protect-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Nos formations</span>
            <h2>Trouvez la formation qui vous correspond</h2>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="how-to-protect-content">
                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-hand-wash"></i>
                            </div>
                            <h3>Wash Your Hands</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-people"></i>
                            </div>
                            <h3>Avoid Close Contact</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-home-plan"></i>
                            </div>
                            <h3>Stay at Home</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-face-detection"></i>
                            </div>
                            <h3>Don’t Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-mask"></i>
                            </div>
                            <h3>Wear a Face Mask</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="how-to-protect-image">
                    <img src="assets/img/how-to-protect/img1.jpg" class="main-image main-image1" alt="image">
                    <img src="assets/img/how-to-protect/img2.jpg" class="main-image main-image2" alt="image">
                    <img src="assets/img/how-to-protect/img3.jpg" class="main-image main-image3" alt="image">
                    <img src="assets/img/how-to-protect/img4.jpg" class="main-image main-image4" alt="image">
                    <img src="assets/img/how-to-protect/img5.jpg" class="main-image main-image5" alt="image">
                    <img src="assets/img/how-to-protect/img6.jpg" class="main-image main-image6" alt="image">

                    <div class="how-to-protect-shape">
                        <img src="assets/img/how-to-protect/shape1.png" alt="image">
                        <img src="assets/img/how-to-protect/shape2.png" alt="image">
                        <img src="assets/img/how-to-protect/shape3.png" alt="image">
                        <img src="assets/img/how-to-protect/shape4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="how-to-protect-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to Protect Yourself</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="how-to-protect-content-list">
                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-hand-wash"></i>
                            </div>
                            <h3>Wash Your Hands</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-people"></i>
                            </div>
                            <h3>Avoid Close Contact</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-home-plan"></i>
                            </div>
                            <h3>Stay at Home</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-face-detection"></i>
                            </div>
                            <h3>Don’t Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-mask"></i>
                            </div>
                            <h3>Wear a Face Mask</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="how-to-protect-img">
                    <img src="assets/img/how-to-protect/img.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="prevention-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to Protect Yourself</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Should Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon1.png" alt="image">
                            </div>
                            <h3>Wash Your Hand For 20 Sec</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon2.png" alt="image">
                            </div>
                            <h3>Wear Mask All The Time</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon3.png" alt="image">
                            </div>
                            <h3>Avoid Contact With Animals</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon4.png" alt="image">
                            </div>
                            <h3>Always Cover Your Sneeze</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Shouldn’t Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon5.png" alt="image">
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon6.png" alt="image">
                            </div>
                            <h3>Don't Handshake</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon7.png" alt="image">
                            </div>
                            <h3>Don't Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon8.png" alt="image">
                            </div>
                            <h3>Avoid Travel</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="prevention-shape1"><img src="assets/img/prevention-shape1.png" alt="image"></div>
    <div class="prevention-shape2"><img src="assets/img/prevention-shape2.png" alt="image"></div>
    <div class="prevention-shape3"><img src="assets/img/prevention-shape3.png" alt="image"></div>
    <div class="prevention-shape4"><img src="assets/img/prevention-shape4.png" alt="image"></div>
</section>

<section class="spread-virus-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Spreads Virus</span>
            <h2>How Covid-19 Spreads</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img1.jpg" alt="image">
                    <h3>Person to Person</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img2.jpg" alt="image">
                    <h3>Infected Person Coughs</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img3.jpg" alt="image">
                    <h3>Close Contact Another</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-image faq-bg1">
                    <img src="assets/img/faq-img.jpg" alt="image">

                    <a href="https://www.youtube.com/watch?v=BtN-goy9VOY" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                What is the source of the virus?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How does the virus spread?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Who has had COVID-19 spread the illness to others?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Will warm weather stop the outbreak of COVID-19?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                What is community spread?
                                <i class="flaticon-add"></i>
                            </a>
                        </div>

                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="faq-shape1"><img src="assets/img/faq-shape1.png" alt="image"></div>
    <div class="faq-shape2"><img src="assets/img/faq-shape2.png" alt="image"></div>
</section>

<section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area ptb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
