import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BASE_URL} from '../../shared/util/REST_URL';

const baseUrl = BASE_URL + '/partages';

@Injectable({
  providedIn: 'root'
})
export class PartageService {
  constructor(private httpClient: HttpClient) { }


  public create(data) {
    return this.httpClient.post(baseUrl, data);
  }

  public update(id: number, data) {
    return this.httpClient.put(`${baseUrl}/${id}`, data);
  }

  public delete(id: number) {
    return this.httpClient.delete(`${baseUrl}/${id}`);
  }


  public getPartageModelId(id: number) {
    return this.httpClient.get(`${baseUrl}/${id}`);
  }

  public getDepartementStructureId(id: number) {
    return this.httpClient.get(`${BASE_URL}/departements/structure/${id}`);
  }


  public getPartageByOwner(titre?: string, type?: string, destinateur?: string, page?, size?) {
    page = page === undefined ? 0 : page;
    size = size === undefined ? 100 : size;

    if (destinateur && type && titre) {
      return this.httpClient.get(`${baseUrl}/byOwner?titre=${titre}&type=${type}&destinateur=${destinateur}&page=${page}&size=${size}`);
    } else if (destinateur && type) {
      return this.httpClient.get(`${baseUrl}/byOwner?ttype=${type}&destinateur=${destinateur}&page=${page}&size=${size}`);
    } else if (destinateur && !type) {
      return this.httpClient.get(`${baseUrl}/byOwner?destinateur=${destinateur}&page=${page}&size=${size}`);
    } else if (!destinateur && type) {
      return this.httpClient.get(`${baseUrl}/byOwner?type=${type}&page=${page}&size=${size}`);
    } else {
      return this.httpClient.get(`${baseUrl}/byOwner?page=${page}&size=${size}`);
    }
  }

  public getPartageByDepartementId(id: number, titre?: string, type?: string, page?, size?) {
    page = page === undefined ? 0 : page;
    size = size === undefined ? 50 : size;
    return this.httpClient.get(`${baseUrl}/departements/${id}?titre=${titre}&type=${type}&page=${page}&size=${size}`);
  }

  public getPartageByEnseignantStructureId(id_structure: number, titre?: string, type?: string, page?, size?) {
    page = page === undefined ? 0 : page;
    size = size === undefined ? 50 : size;
    return this.httpClient.get(`${baseUrl}/enseignants/${id_structure}?titre=${titre}&type=${type}&page=${page}&size=${size}`);
  }

  public getPartageByEtudiantStructureId(id_structure: number, titre?: string, type?: string, page?, size?) {
    page = page === undefined ? 0 : page;
    size = size === undefined ? 50 : size;
    return this.httpClient.get(`${baseUrl}/etudiants/${id_structure}?titre=${titre}&type=${type}&page=${page}&size=${size}`);
  }

  public getPartageByGrandPublicStructureId(id_structure: number, titre?: string, type?: string, page?, size?) {
    page = page === undefined ? 0 : page;
    size = size === undefined ? 50 : size;
    return this.httpClient.get(`${baseUrl}/grand-public/${id_structure}?titre=${titre}&type=${type}&page=${page}&size=${size}`);
  }



  public onUpload(file: File) {
    const formData = new FormData();

    formData.append('file', file);
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');


    return this.httpClient.post(`${BASE_URL}/files/simpleUpload/COURRIER`, formData);
  }

  public uploadPromise(file: File) {
    const promise = new Promise((resolve, reject) => {
      this.onUpload(file)
        .subscribe(response => {
            reject(response);
          }, error => {
            console.log('uploadPromise error', error);
            resolve(error.error.text);
          }
        );
    });
    return promise;
  }



  public uploadPromises(files: File[]) {
    const promises = files.map((file) => this.uploadPromise(file));
    return Promise.all(promises);
  }

}
