import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/service/auth.service";
import { SpinnerService } from "../../shared/service/spinner.service";
import { StructuresService } from "../../shared/service/structures.service";
import { SubscriptionHandle } from "../../shared/model/SubscriptionHandle";
import { Util_fonction } from "../../shared/model/util_fonction";

import { environment } from "../../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { PagesInformationDataService } from "../../shared/service/pages-information-data.service";
// import Swiper from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

declare var $: any;

// const swiper = new Swiper(".swiper", {
//     // Optional parameters
//     direction: "vertical",
//     loop: true,

//     // If we need pagination
//     pagination: {
//         el: ".swiper-pagination",
//     },

//     // Navigation arrows
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },

//     // And if we need scrollbar
//     scrollbar: {
//         el: ".swiper-scrollbar",
//     },
// });

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    constructor(
        protected router: Router,
        protected spinner: SpinnerService,
        protected authService: AuthService,
        private pageInfoService: PagesInformationDataService,
        private structureService: StructuresService,
        private changD: ChangeDetectorRef
    ) {}
    @Output() changeStructureDadmission = new EventEmitter<any>();

    currentUserData = sessionStorage.getItem("user");
    sub = new SubscriptionHandle();
    _http = environment._http;
    logoUniv = environment.logoUniv;
    adresseUniv = environment.adresseUniv;
    emailUniv = environment.emailUniv;
    contactUniv = environment.contactUniv;
    currentEnv = environment.currentEnv;
    ListDesStructures: any;

    ngOnInit(): void {
        this.GetStructures();
        this.GetSlides();
    }

    GetStructures() {
        this.structureService.getStuctures().subscribe((res) => {
            this.ListDesStructures = res.filter((s) => s.type !== "RECTORAT");
        });
    }

    CheckIsNotEmpty(element) {
        return Util_fonction.checkIfNoTEmpty(element);
    }

    slides;
    GetSlides() {
        this.pageInfoService
            .getPageInformations("homeImageSlide")
            .subscribe((infoServiceResponse) => {
                this.slides = infoServiceResponse.content;
            });
    }

    // CHOIX DE LA STRUCTURE
    StructureChoose() {
        if (
            Util_fonction.checkIfNoTEmpty(
                sessionStorage.getItem("structureData")
            )
        ) {
            $("#BackChooseModal").modal({
                backdrop: "static",
                keyboard: false,
            });
            $("#BackChooseModal").appendTo("body");
        } else {
            $("#StructureListModal").modal({
                backdrop: "static",
                keyboard: false,
            });
            $("#StructureListModal").appendTo("body");
        }
    }

    // ANNULATION DE TOUT LE PROCESSUCE
    backToStructureChoose() {
        this.GetStructures();
        sessionStorage.setItem("structureIsChange", "true");
        sessionStorage.removeItem("structureData"); // EFFACE LES DONNEES DE L'ANCIEN STRUCTURE
        this.StructureChoose();
    }

    // NAVIGATION VERS LES EVENEMENT D'INSCRIPTION DE LA STRUCTURE SELECTIONNEE
    GoToInscriptionEvenements(structure) {
        sessionStorage.setItem("structureData", JSON.stringify(structure));
        // DETECT QUE LES DONNEES DE LA STRUCTURE A ETE CHANGER
        if (
            Util_fonction.checkIfNoTEmpty(
                sessionStorage.getItem("structureIsChange")
            )
        ) {
            $("#StructureListModal").modal("hide");
            sessionStorage.removeItem("structureIsChange");
            this.changeStructureDadmission.emit(true);
        } else {
            $("#StructureListModal").modal("hide");
            this.router.navigate(["Evenements_Inscription"]);
        }
    }

    goToProfil() {
        if (sessionStorage.getItem("user")) {
            this.authService.isOpen = false;
            this.router.navigateByUrl("forum");
        } else {
            this.authService.isOpen = true;
            $("#AuthModal").modal({
                backdrop: "static",
                keyboard: false,
            });
            $("#AuthModal").appendTo("body");
        }
    }

    logOut() {
        sessionStorage.clear();
        window.location.reload();
    }
    user: any;
    username: string;
    password: string;
    loading = false;
    SubmitAuthForm() {
        this.loading = true;
        const data = {
            username: this.username,
            password: this.password,
        };
        this.authService.authenticate(data).subscribe((response) => {
            this.user = response;
            sessionStorage.setItem("token", response["token"]);
            sessionStorage.setItem("user", JSON.stringify(response));
            this.authService
                .getAnneeEnCours(this.user.structure.id)
                .subscribe((response) => {
                    sessionStorage.setItem(
                        "dateEnCours",
                        JSON.stringify(response)
                    );
                    $("#AuthModal").modal("hide");
                    // this.router.navigateByUrl('forum');
                    // window.location.href = 'usttb' ? 'http://localhost:4200/#/forum' : 'http://localhost:4200#//forum'
                    window.location.href =
                        this.currentEnv === "usttb"
                            ? "https://portail.usttb.online/#/forum"
                            : "https://portail.ussgb.online/#/forum";
                });
        });
    }

    navigateToAdmin() {
        window.location.href =
            this.currentEnv === "usttb"
                ? "https://usttb.online"
                : "https://ussgb.online";
    }
    // login(data) {
    //     this.spinner.show('Connexion ....');
    //     this.authService.authenticate(data).subscribe(
    //         (response: any) => {
    //             this.spinner.hide();
    //             this.authService.sigIn(response);
    //         },
    //         error => {
    //             this.spinner.hide();
    //         },
    //     );
    // }
}
