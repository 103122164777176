<app-header></app-header>

<div class="container text-center shadow-lg mt-4 background-image-container">
    <div class="section-title">
        <div>
            <h2 class="actu">ACTUALITÉ</h2>
            <span class="sub-title">
                Soyer à la page, en suivant nos actualité
            </span>
        </div>
    </div>
</div>

<div class="container shadow-lg mt-3 p-3">
    <div>
        <p>Selon la structure</p>
        <div class="row">
            <div class="col-2">
                <select
                    class="form-control"
                    style="border: 1px solid #000"
                    name="structures"
                    id="structures"
                    (change)="structureSelected($event)"
                >
                    <option value="tout">Tout</option>
                    <option
                        value="{{ structure.id }}"
                        *ngFor="let structure of ListDesStructures"
                    >
                        {{ structure.sigle }}
                    </option>
                </select>
            </div>
            <div class="col-10">
                <div class="pl-4" *ngIf="!!selectedStructure">
                    <h6 class="text-uppercase">
                        <span class="badge badge-warning mr-2">Info </span>
                        {{ selectedStructure[0].sigle }}
                    </h6>
                    <h6>{{ selectedStructure[0].nom }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-4 p-2 mt-3 text-center" *ngFor="let info of InfoData">
            <!-- <img
            src="{{ this._http }}{{ livre.image }}"
            style="height: auto; width: max-content"
            alt=""
        /> -->
            <div
                class="mr-3 p-3 shadow-lg bg-white"
                style="border-radius: 10px"
            >
                <div class="text-left ml-2">
                    <span class="badge badge-warning">{{
                        info.structure.sigle
                    }}</span>
                </div>
                <h6>{{ info.titre }}</h6>
                <button class="btn btn-outline-dark" (click)="showDetail(info)">
                    <i class="fa fa-eye"></i>
                    voir les details
                </button>
            </div>
        </div>
    </div>

    <!-- MODAL-->
    <div
        class="modal fade"
        id="AnnonceModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4
                        class="modal-title font-weight-bold"
                        id="exampleModalLabel"
                        *ngIf="!!Details"
                    >
                        {{ Details.titre }}
                    </h4>
                    <button type="button" mat-button data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" *ngIf="!!Details">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4></h4>
                                </div>
                                <div class="card-body">
                                    <!-- <div
                                        *ngFor="let information of informations"
                                    > -->
                                    <div
                                        class="panel-body"
                                        [innerHTML]="Details.contenu"
                                    ></div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--    <h6>- Lister les structures et institue + option Toutes les actualités, dans un select dans uns petit sidebar </h6>-->
    <!--    <p>En selectionnant une option un swipper slide liste tous les actus selon L'option selectionner : </p>-->
    <!--    <p>Toutes les actualités : list un mixte des actu de tous les structures </p>-->
    <!--    <p>En selectionnant une actus son contenu s'affiche dans un container en bas !</p>-->

    <!--   //*- Liste des swipper slide à choisir.-->
    <!-- 
    <div>
        <div class="m-3">
            <swiper
                [loop]="true"
                [autoHeight]="true"
                [allowTouchMove]="false"
                [zoom]="true"
                [autoplay]="{ delay: 1000, disableOnInteraction: false }"
                [pagination]="true"
                [navigation]="true"
            >
                <ng-template
                    swiperSlide
                    data-swiper-autoplay="6000"
                    *ngFor="let info of InfoData"
                >
                    <h5 class="text-uppercase font-weight-bold text-left">
                        {{ info.structure.sigle }}
                    </h5>

                    <h4>{{ info.titre }}</h4>

                    <div [innerHTML]="info.contenu"></div>
                    <div>.</div>
                </ng-template>
            </swiper>
        </div>
    </div> -->
</div>
