
export function formatDate(){
    const newDate = new Date();//.toString();
    const dateBuild = [newDate.getFullYear(), newDate.getMonth(), newDate.getDay()];
    const date = dateConvert(dateBuild);
    return date;
}

export function dateConvert(date){
    let result = date;
    console.log(typeof date);

    if (typeof date !== 'string') {
        // tslint:disable-next-line:max-line-length
        result = date[0] + ((date[1].toString().length <= 1) ? '-0' : '-') + date[1] + ((date[2].toString().length <= 1) ? '-0' : '-') + date[2];
    }
    return result;
}
