import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BASE_URL, HEADERS} from '../util/REST_URL';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private currentUser = new BehaviorSubject<any>(null);
    private isConnected = new BehaviorSubject<boolean>(false);

    public isOpen = false;
    public readonly currentUser$ = this.currentUser.asObservable();
    public readonly isConnected$ = this.isConnected.asObservable();

    roleAs: any;

    constructor(private httpClient: HttpClient) {}


    authenticate(data: any) {
        console.log('Authenticate ===>', data);
        return this.httpClient.post('https://api.ussgb.online/v0/authenticate', data);
    }

    public getAnneeEnCours(id): Observable<any> {
        return this.httpClient.get('https://api.ussgb.online/v0/annees/structure/encours/' + `${id}`);
    }



    sendToken(data: any) {
        return this.httpClient.post(BASE_URL + '/users/resetPassword', data);
    }

    updateUsername(data: any): Observable<any> {
        return this.httpClient.put(BASE_URL + `/users/updateUsername`, data, {headers: HEADERS});
    }

    updatePassword(data: any): Observable<any> {
        return this.httpClient.put(BASE_URL + `/users/updatePassword`, data, {headers: HEADERS});
    }

    resetPassword(email: any) {
        return this.httpClient.post(BASE_URL + '/users/request/resetPassword', {
            email
        });
    }

    isUserLoggedIn() {
        const user = sessionStorage.getItem('username');
        return !(user === null);
    }



    getAllRoles() {
        this.roleAs = JSON.parse(sessionStorage.getItem('roles'));
        return this.roleAs;
    }

    sigIn(value: any) {
        this.currentUser.next(value);
        this.isConnected.next(true);
        sessionStorage.setItem('user', JSON.stringify(value));
        sessionStorage.setItem('username', value.username);
        sessionStorage.setItem('token', value.token);
        window.location.reload();
    }

    sigOut() {
        this.currentUser.next(null);
        this.isConnected.next(false);
        sessionStorage.clear();
    }
    getCurrentUserId(): number {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.id) {
            return user.id;
        } else {
            return null; // Retournez null ou une valeur par défaut si l'utilisateur n'est pas connecté ou si l'ID n'est pas disponible.
        }
    }


}
