<app-header></app-header>
<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css"
    integrity="sha256-46r060N2LrChLLb5zowXQ72/iKKNiw/lAmygmHExk/o="
    crossorigin="anonymous"
/>

<div class="container containerBande text-center shadow-lg mt-4">
    <div class="section-title mt-2">
        <div class="espace">
            <h2>ESPACE FORUM</h2>
        </div>
    </div>
</div>

<div class="container mt-4 text-center">
    <img
        src="../../../../assets/img/universite/login.png"
        style="height: auto; width: 280px"
        alt=""
    />
    <h5>Connectez-vous pour accéder au forum</h5>
</div>

<div class="container shadow-lg mt-2">
    <!-- Forum List -->
    <div class="inner-main-body p-2 p-sm-3 collapse forum-content show">
        <div class="card mb-4 shadow-lg" *ngFor="let subject of Subjects">
            <div class="card-body p-2 p-sm-3">
                <div class="media forum-item">
                    <a
                        href="#"
                        data-toggle="collapse"
                        data-target=".forum-content"
                        ><img
                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                            class="mr-3 rounded-circle"
                            width="50"
                            alt="User"
                    /></a>

                    <div class="media-body">
                        <div class="timeline-content">
                            <p class="lead">
                                <i class="fa fa-quote-left fa-fw pull-left"></i>
                                <span class="subject">{{
                                    subject.content
                                }}</span
                                ><br />
                                <i
                                    class="fa fa-quote-right fa-fw pull-right"
                                ></i>
                                <span class="nameStruct">{{
                                    subject.structure.nom
                                }}</span
                                ><br />
                                <span class="sigle">{{
                                    subject.structure.sigle
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-footer ml-4">
                    <!-- boite Modal-->
                    <a
                        class="mr-6"
                        data-toggle="modal"
                        data-target="#threadModal"
                        href="#"
                        data-toggle="collapse"
                        data-target=".forum-content"
                        (click)="showComment(subject.id)"
                    >
                        <i class="fas fa-comment"></i>
                        commentaires
                    </a>

                    <!-- Modal -->
                    <div
                        class="modal fade"
                        id="threadModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="threadModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5
                                        class="modal-title"
                                        id="threadModalLabel"
                                    ></h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <!-- Ajoutez ici les champs du formulaire -->
                                    <form>
                                        <div class="form-group">
                                            <textarea
                                                class="form-control"
                                                id="content"
                                                rows="3"
                                                placeholder="Commenter"
                                            ></textarea>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                    >
                                        Post
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Fin de la boite Modal  -->
                </div>
            </div>

            <div class="inner-main-body p-2 p-sm-3 collapse forum-content show">
                <div class="card mb-2">
                    <!-- Nested card content goes here -->
                </div>
            </div>
        </div>
    </div>
    <!-- /Forum List -->

    <!-- Forum Detail -->
    <div class="inner-main-body p-2 p-sm-3 collapse forum-content">
        <a
            href="#"
            class="btn btn-light btn-sm mb-3 has-icon"
            data-toggle="collapse"
            data-target=".forum-content"
            ><i class="fa fa-arrow-left mr-2"></i>retour</a
        >
        <div class="timeline-comment-box comment-section">
            <div class="user">
                <img src="https://bootdey.com/img/Content/avatar/avatar6.png" />
            </div>
            <div class="input">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control rounded-corner"
                        [(ngModel)]="commentContent"
                        placeholder="Ecrivez votre commentaire..."
                    />

                    <span class="input-group-btn p-l-10">
                        <button
                            class="btn btn-primary f-s-12 rounded-corner"
                            type="button"
                            data-toggle="modal"
                            data-target="#loginModal"
                            (click)="addComment()"
                        >
                            Commenter
                        </button>
                    </span>
                </div>
            </div>
            <div class="card mb-2" *ngFor="let comment of comments">
                <div class="card-body">
                    <div class="media forum-item">
                        <a href="javascript:void(0)" class="card-link">
                            <img
                                src="https://bootdey.com/img/Content/avatar/avatar2.png"
                                class="rounded-circle"
                                width="50"
                                alt="User"
                            />
                        </a>
                        <div class="media-body ml-3">
                            <p href="javascript:void(0)" class="text-secondary">
                                {{ comment.user.fullName }}
                            </p>
                            <div class="mt-3 font-size-sm">
                                <p>{{ comment.content }}</p>
                            </div>
                            <small class="text-muted ml-2">{{
                                comment.time
                            }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <!--Boite modal  -->
            <div
                class="modal fade"
                id="loginModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="loginModalLabel"
                aria-hidden="true"
                *ngIf="verifiedUserConnect()"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-white">
                            <h5
                                class="modal-title text-white"
                                id="loginModalLabel"
                            >
                                <i class="fas fa-user"></i> Connectez-vous
                            </h5>
                            <button
                                type="button"
                                class="close text-white"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <!-- Les champs de connexion -->
                            <form [formGroup]="form">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                ><i class="fas fa-user"></i
                                            ></span>
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="username"
                                            placeholder="Nom d'utilisateur"
                                            formControlName="username"
                                            required
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                ><i class="fas fa-lock"></i
                                            ></span>
                                        </div>
                                        <input
                                            type="password"
                                            class="form-control"
                                            id="password"
                                            formControlName="password"
                                            placeholder="Mot de passe"
                                            name="password"
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                            >
                                <i class="fas fa-times"></i> Fermer
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                (click)="login()"
                            >
                                <i class="fas fa-sign-in-alt"></i> Se connecter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fin de laboite modal -->
        </div>
    </div>
    <!-- /Forum Detail -->

    <!-- New Thread Modal -->
</div>

<div
    class="modal fade"
    id="threadModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="threadModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <form>
                <div
                    class="modal-header d-flex align-items-center bg-primary text-white"
                >
                    <h6 class="modal-title mb-0" id="threadModalLabel">
                        New Discussion
                    </h6>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="threadTitle">Title</label>
                        <input
                            type="text"
                            class="form-control"
                            id="threadTitle"
                            placeholder="Enter title"
                            autofocus=""
                        />
                    </div>
                    <textarea
                        class="form-control summernote"
                        style="display: none"
                    ></textarea>

                    <div
                        class="custom-file form-control-sm mt-3"
                        style="max-width: 300px"
                    >
                        <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            multiple=""
                        />
                        <label class="custom-file-label" for="customFile"
                            >Attachment</label
                        >
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-light"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary">Post</button>
                </div>
            </form>
        </div>
    </div>
</div>
