import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BASE_URL} from '../../shared/util/REST_URL';

const baseUrl = BASE_URL + '/partages';

@Injectable({
  providedIn: 'root'
})
export class FormationService {
  constructor(private httpClient: HttpClient) { }



  public getOptionByStructureId(structureId: number) {
    return this.httpClient.get(`${BASE_URL}/options/structure/${structureId}`);
  }

  public getOptionByDepartementId(departementId: number) {
    return this.httpClient.get(`${BASE_URL}/options/departements/${departementId}`);
  }


}
