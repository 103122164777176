import { Component, OnInit } from '@angular/core';
import {SpinnerService} from '../../service/spinner.service';

@Component({
  selector: 'app-aicha-spinner',
  templateUrl: './aicha-spinner.component.html',
  styleUrls: ['./aicha-spinner.component.css']
})
export class AichaSpinnerComponent implements OnInit {


  constructor(
    public service: SpinnerService,
  ) {}


  ngOnInit() {
  }

}
