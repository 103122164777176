
import {HttpHeaders} from '@angular/common/http';
// export const BASE_URL = 'https://161.97.139.215:8080/ussgb'; // -- Ussgb online
// export const BASE_URL = 'http://194.163.155.205:8080/ussgb'; // -- local commun
export const BASE_URL = 'https://api.ussgb.online/v1'; // -- serveur test
// export const AUTH_BASE_URL = 'http://161.97.139.215:7098'; // -- serveur test


let token = '';
if (sessionStorage.getItem('token') !== null) {
    token = sessionStorage.getItem('token');
}
export const HEADERS = new HttpHeaders().set('Authorization', `Bearer ${token}`);

export let structureSelected = {etat: false, Data: null};
