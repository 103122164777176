import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/service/auth.service';
import {_HTTP} from "../../shared/model/CONSTANTES";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    _http = _HTTP;
    logoUniv = environment.logoUniv;
    adresseUniv = environment.adresseUniv;
    emailUniv = environment.emailUniv;
    contactUniv = environment.contactUniv;
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }


}
