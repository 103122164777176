<app-onepage-navbar></app-onepage-navbar>

<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Stay Safe &</span>
                            <h1>Wash Your Hands Frequently</h1>
                            <p>Stay Aware of the Coronavirus, Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>

                            <div class="btn-box">
                                <a href="https://www.youtube.com/watch?v=8c_UJwLq8PI" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/corona1.png" alt="image">
                            <img src="assets/img/corona2.png" alt="image">
                            <img src="assets/img/corona3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                    <img src="assets/img/about-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Covid-19</span>
                    <h2>About Coronavirus Disease</h2>
                    <p>In 2019, the Centers for Disease Control and Prevention (CDC) started monitoring the outbreak of a new coronavirus, SARS-CoV-2, which causes the respiratory illness now known as COVID-19. Authorities first identified the virus in Wuhan, China.</p>
                    <p>More than 78,191 people have contracted the virus in China. Health authorities have identified many other people with COVID-19 around the world, including in the United States. On January 31, 2020, the virus passed from one person to another in the U.S.</p>
                    <blockquote>
                        <p>The World Health Organization (WHO) have declared a public health emergency relating to COVID-19.</p>
                    </blockquote>
                    <p>Since then, this strain has been diagnosed in several U.S. residents. The CDC have advised that it is likely to spread to more people. COVID-19 has started causing disruption in at least 25 other countries.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area">
    <div class="container">
        <div class="cta-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-5">
                            <img src="assets/img/man.png" alt="image">
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <h3>We Operate 24h a day - Every Day!</h3>
                            <p>Call us if you have any problems.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="cta-btn">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="spread-virus-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Spreads Virus</span>
            <h2>How Covid-19 Spreads</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img1.jpg" alt="image">
                    <h3>Person to Person</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img2.jpg" alt="image">
                    <h3>Infected Person Coughs</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-spread-virus-box">
                    <img src="assets/img/spread-virus/img3.jpg" alt="image">
                    <h3>Close Contact Another</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="symptoms" class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <h2>COVID-19 Coronavirus Outbreak</h2>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <img src="assets/img/funfacts.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="187">00</h3>
                                <p>Total Country</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="294110">00</h3>
                                <p>Confirmed Cases</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="12944">00</h3>
                                <p>Deaths</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="100345">00</h3>
                                <p>Recovered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="funfacts-info">
                    <p>Source: <a href="https://www.who.int/" target="_blank">WHO</a> on March 23, 2020</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="prevention" class="how-to-protect-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to Protect Yourself</h2>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="how-to-protect-content">
                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-hand-wash"></i>
                            </div>
                            <h3>Wash Your Hands</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-people"></i>
                            </div>
                            <h3>Avoid Close Contact</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-home-plan"></i>
                            </div>
                            <h3>Stay at Home</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-face-detection"></i>
                            </div>
                            <h3>Don’t Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-mask"></i>
                            </div>
                            <h3>Wear a Face Mask</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>

                    <div class="content">
                        <div class="text">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="how-to-protect-image">
                    <img src="assets/img/how-to-protect/img1.jpg" class="main-image main-image1" alt="image">
                    <img src="assets/img/how-to-protect/img2.jpg" class="main-image main-image2" alt="image">
                    <img src="assets/img/how-to-protect/img3.jpg" class="main-image main-image3" alt="image">
                    <img src="assets/img/how-to-protect/img4.jpg" class="main-image main-image4" alt="image">
                    <img src="assets/img/how-to-protect/img5.jpg" class="main-image main-image5" alt="image">
                    <img src="assets/img/how-to-protect/img6.jpg" class="main-image main-image6" alt="image">

                    <div class="how-to-protect-shape">
                        <img src="assets/img/how-to-protect/shape1.png" alt="image">
                        <img src="assets/img/how-to-protect/shape2.png" alt="image">
                        <img src="assets/img/how-to-protect/shape3.png" alt="image">
                        <img src="assets/img/how-to-protect/shape4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="faq" class="faq-section-two bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>

        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-book-reader'></i><span>COVID-19 Basics</span></a></li>
                
                <li><a href="#"><i class='bx bx-command'></i><span>Prevention</span></a></li>

                <li><a href="#"><i class='bx bx-user-check'></i><span>Self Quarantine</span></a></li>

                <li><a href="#"><i class='bx bx-user-pin'></i><span>For Caregivers</span></a></li>

                <li><a href="#"><i class='bx bx-info-square'></i><span>Terms to Know</span></a></li>
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExTwo" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSix">
                                <a data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSix" class="collapse show" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeven">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEight">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNine">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExThree" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEleven">
                                <a data-toggle="collapse" data-parent="#accordionExThree" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEleven" class="collapse show" role="tabpanel" aria-labelledby="headingEleven" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwelve">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwelve" class="collapse" role="tabpanel" aria-labelledby="headingTwelve" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThirteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThirteen" class="collapse" role="tabpanel" aria-labelledby="headingThirteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFourteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFourteen" class="collapse" role="tabpanel" aria-labelledby="headingFourteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFifteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFifteen" class="collapse" role="tabpanel" aria-labelledby="headingFifteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFour" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSixteen">
                                <a data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSixteen" class="collapse show" role="tabpanel" aria-labelledby="headingSixteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeventeen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeventeen" class="collapse" role="tabpanel" aria-labelledby="headingSeventeen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEighteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEighteen" class="collapse" role="tabpanel" aria-labelledby="headingEighteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNineteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNineteen" class="collapse" role="tabpanel" aria-labelledby="headingNineteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwenty">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwenty" class="collapse" role="tabpanel" aria-labelledby="headingTwenty" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFive" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyOne">
                                <a data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyOne" class="collapse show" role="tabpanel" aria-labelledby="headingTwentyOne" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwentyTwo" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyThree" class="collapse" role="tabpanel" aria-labelledby="headingTwentyThree" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFour" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFour" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFive" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFive" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="doctors" class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area pb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/home-six" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/home-six" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Covid</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Adjust</span>
                        <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Coronavirus</span>
                        <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Drop Us A Line</h3>
                    <p>We're happy to answer any questions you have or provide you with an estimate. Just send us a message in the form below with any questions you may have.</p>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Name <span>*</span></label>
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Email <span>*</span></label>
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Your email address">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Phone Number <span>*</span></label>
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your phone number">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Your Message <span>*</span></label>
                                    <textarea name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..."></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='flaticon-plane'></i> Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <h3>Here to Help</h3>
                    <p>Have a question? You may find an answer in our <a href="faq.html">FAQs</a>. But you can also contact us.</p>

                    <ul class="contact-list">
                        <li><i class='bx bx-map'></i> Location: <a href="#">Wonder Street, USA, New York</a></li>
                        <li><i class='bx bx-phone-call'></i> Call Us: <a href="tel:+01321654214">+01 321 654 214</a></li>
                        <li><i class='bx bx-envelope'></i> Email Us: <a href="mailto:hello@qovid.com">hello@qovid.com</a></li>
                        <li><i class='bx bx-microphone'></i> Fax: <a href="tel:+123456789">+123456789</a></li>
                    </ul>

                    <h3>Opening Hours:</h3>
                    <ul class="opening-hours">
                        <li><span>Monday:</span> 8AM - 6AM</li>
                        <li><span>Tuesday:</span> 8AM - 6AM</li>
                        <li><span>Wednesday:</span> 8AM - 6AM</li>
                        <li><span>Thursday - Friday:</span> 8AM - 6AM</li>
                        <li><span>Sunday:</span> Closed</li>
                    </ul>

                    <h3>Follow Us:</h3>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-skype'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>