import {Component, OnInit} from '@angular/core';
import {FormationService} from './formation.service';
import {OptionModel} from './formation.model';

@Component({
    selector: 'app-formation',
    templateUrl: './formation.component.html',
    styleUrls: ['./formation.component.scss']
})
export class FormationComponent implements OnInit {

    currentUser: any;
    structure: any;
    options: OptionModel[];

    constructor(public service: FormationService) {
    }

    ngOnInit(): void {
        this.currentUser = JSON.parse(sessionStorage.getItem('user')).users;
        this.structure = JSON.parse(sessionStorage.getItem('user')).structure;
        console.log('currentUser', this.currentUser);
        console.log('structure', this.structure);
        if (this.structure) {
            this.initData(this.structure.id);
        }
    }

    initData(id) {
        this.initOption(id);
    }

    initOption(id) {
        this.service.getOptionByStructureId(id)
            .subscribe((response: any) => {
                    console.log('initOption data', response);
                    this.options = response;
                }, error => {
                    console.log('initOption error', error);
                }
            );
    }

}
