<app-header></app-header>


<div class="container text-center shadow-lg mt-3" >
    <div class="sectionTitre">
        <div>
            <h2 class="formation">FORMATIONS</h2>
            <span class="sub-title" >
                    Découvez nos filières et selon la faculte ou l'institue de votre choix
                </span>
        </div>
    </div>
</div>

<div class="container col-md-9 shadow-lg mt-4 p-3" style="border-radius: 15px; background-color: #CCCCCC">
    <div class="row">
        <div class="col-md-3">
            <div class="container" style="position: sticky">
                <div *ngFor="let structure of Structures">
                    <div class="row shadow-sm mt-3 mb-3 bg-white" style="border-radius: 15px; cursor: pointer" (click)="selectStructure(structure)">
                        <div class="col-5" style="display: flex; justify-items: center; align-items: center">
                            <div>
                                <img src="{{_http+structure.logo}}" style="height: auto; width: 175px" alt="">
                            </div>
                        </div>
                        <div class="col-7" style="border-left: 3px solid #006e90 ;">
                            <div class="row">
                                <div class="col-8 pt-4" style=" display: flex; justify-content: center; align-content: center">
                                    <h3>{{structure.sigle}}</h3>
                                </div>
                                <div class="col-1 pt-3" style="display: flex; justify-content: end; align-items: center">
                                 <span style="color: #006e90; font-size: 18px" *ngIf="selected === structure.id.toString()">
                                    <i class="fa fa-arrow-circle-o-right text-primary"></i>
                                     <!--                                <i class="fa fa-chevron-right"></i>-->
                                 </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="p-3 bg-white" style="border-radius: 15px;">
                <section class="faq-area" *ngIf="selectedStructureData !== undefined">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                    <div class="card" *ngFor="let list of SelectedFiliereList">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <div class="d-flex">
                                                <div>
                                                    <img src="{{_http+selectedStructureData.logo}}" style="height: auto; width: 55px" alt="">
                                                </div>
                                                <div class="pl-3">
                                                    <a data-toggle="collapse" data-parent="#accordionEx" href="#{{'collapseOne'+list[1][0].id}}" aria-expanded="false" aria-controls="collapseOne">
                                                        {{list[1][0].filiere.codeFiliere}} - {{list[1][0].filiere.nom}}
                                                        <i class="flaticon-add" style="right: 0"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="{{'collapseOne'+list[1][0].id}}" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                            <div class="card-body">
                                                <h6>les Options</h6>
                                                <div class="mb-3" *ngFor="let option of list[1]">
                                                    <span [className]="option.cursus === 'LICENCE' ? 'badge badge-primary': 'badge badge-info'">{{option.cursus}}</span>
                                                    <p>{{option.codeOption}} - {{option.nom}}</p>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section *ngIf="selectedStructureData === undefined">
                    <div class="container text-center" >
                        <div class="section-title">
                            <div>
                                <p>
                                    Nous sommes ravis de vous accueillir dans notre communauté académique dynamique et innovante.
                                    Notre site de formation est conçu pour vous offrir une expérience d'apprentissage exceptionnelle,
                                    où vous pourrez acquérir des connaissances pertinentes et développer des compétences essentielles pour réussir
                                    dans votre domaine d'études.
                                </p>
                                <div>
                                    <img src="{{logoUniv}}" style="height: auto; width: 155px" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
