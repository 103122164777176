import {environment} from "../../../../environments/environment";

export const UNIV_SIGLE = 'USSGB';
export const UNIV_NAME = 'Université des Sciences, des Techniques et des Technologies de Bamako';
export const UNIV_NAME_UPPERCASE = 'UNIVERSITE DES SCIENCES, DES TECHNIQUES ET DES TECHNOLOGIES DE BAMAKO';

export const UNIV_MINISTER_UPPERCASE = 'MINISTERE DE L\'EDUCATION NATIONALE, DE L\'ENSEIGNEMENT <br>SUPERIEUR ET LA RECHERCHE SCIENTIFIQUE';

export const UNIV_LOGO = 'assets/media/logos/usttb.png';

export const UNIV_FILIERE = 'Option';
export const UNIV_FILIERE_S = 'Options';
export const UNIV_FILIERE_CODE = 'codeOption';

export const UNIV_OPTION = 'Filière';
export const UNIV_OPTION_S = 'Filières';
export const UNIV_OPTION_CODE = 'codeFiliere';

export const UNIV_IST = 'RECTORAT / FACULTES';





/** ******************************
 * COULEUR POSSIBLE // TODO: à voir pour plus tard
 * *******************************/

export const _HTTP = environment._http;
export const CSS_COLOR = '64b5ea'; // bleu lumineu '64b5ea'; // vert lumineu // '74f8cbe6'

