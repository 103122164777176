import { Component, OnInit } from '@angular/core';
import { CampusService } from '../../shared/service/campus.service';

@Component({
    selector: 'app-campus',
    templateUrl: './campus.component.html',
    styleUrls: ['./campus.component.scss']
})
export class CampusComponent implements OnInit {
    informations: any[]=[];
    content:any[];


    constructor(private campusService: CampusService) { }

    ngOnInit(): void {
        this.campusService.getInformations({ search: 'type=campus' }).subscribe(
            (data) => {
                this.informations = data.content;
                console.log('Données récupérées avec succès');
            },
            (error) => {
                console.error('Une erreur s\'est produite lors de la récupération des données : ', error);
            }
        );
    }
}
