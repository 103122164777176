import { Component, OnInit } from '@angular/core';
import {PartageService} from './partage.service';
import {PartageModel} from './partage.model';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

    partages: PartageModel[];

  constructor(protected partageService: PartageService) { }

  ngOnInit(): void {
   this.initPartage();
  }

  initPartage() {
      this.partageService.getPartageByOwner()
      .subscribe((response: any) => {
          console.log('partage data', response);
          this.partages = response.content;
          console.log('partages', this.partages);
      }, error => {
          console.log('ERROR', error);
          }
      );
  }

}
