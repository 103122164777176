import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Util_fonction} from '../model/util_fonction';

const url = BASE_URL+"/forum/comment";

@Injectable({
    providedIn: 'root'
})
export class GetCommentService {

    constructor(private httpClient: HttpClient) {
    }

    /* */
    public getComment(parameters: any) {
        let param = new HttpParams();
        if (Util_fonction.checkIfNoTEmpty(parameters.idPost)) {
            param = param.append("idPost", parameters.idPost);
        }

        return this.httpClient.get<any>(`${url}`, {params: param});
    }

}
