import { Component, OnInit } from '@angular/core';
import {Util_fonction} from "../../../../shared/model/util_fonction";
import {_HTTP} from "../../../../shared/model/CONSTANTES";
import {environment} from "../../../../../../environments/environment";
import {StructuresService} from "../../../../shared/service/structures.service";

@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {
    _http = _HTTP;
    logoUniv = environment.logoUniv;
    adresseUniv = environment.adresseUniv;
    emailUniv = environment.emailUniv;
    contactUniv = environment.contactUniv;
    ListDesStructures: any;
  constructor(private structureService: StructuresService,) { }

  ngOnInit(): void {
      this.GetStructures();
  }

    CheckIsNotEmpty(element) {
        return  Util_fonction.checkIfNoTEmpty(element);
    }

    GetStructures() {
        this.structureService.getStuctures().subscribe(
            res => {
                this.ListDesStructures = res.filter(s => s.type !== 'RECTORAT');
            }
        );
    }

}
