import { Component, OnInit } from '@angular/core';
import { FraisService } from '../../../shared/service/frais.service';
import {CampusService} from "../../../shared/service/campus.service";
import {PagesInformationDataService} from "../../../shared/service/pages-information-data.service";

@Component({
    selector: 'app-info-visiteur',
    templateUrl: './info-visiteur.component.html',
    styleUrls: ['./info-visiteur.component.scss']
})
export class InfoVisiteurComponent implements OnInit {
    fraisList: any[] = [];
    informations;
    constructor(private fraisService: FraisService,
                private infoService: PagesInformationDataService
                ) { }

    ngOnInit(): void {
        this.fraisService.getFrais({ id_structure:2 }).subscribe(
            (data) => {
                this.fraisList = data;
                console.log('super ');
            },
            (error) => {
                console.error('Une erreur s\'est produite lors de la récupération des données de frais.', error);
            }
        );


        this.infoService.getPageInformations('infovisiteur').subscribe(
            (data) => {
                this.informations = data.content;
                console.log('Données récupérées avec succès');
            },
            (error) => {
                console.error('Une erreur s\'est produite lors de la récupération des données : ', error);
            }
        );
    }
}
