import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Util_fonction} from '../model/util_fonction';

const url = BASE_URL+"/frais";

@Injectable({
    providedIn: 'root'
})
export class FraisService {

    constructor(private httpClient: HttpClient) {
    }

    /* */
    public getFrais(parameters: any) {
        let param = new HttpParams();
        if (Util_fonction.checkIfNoTEmpty(parameters.id_filiere)) {
            param = param.append("id_filiere", parameters.id_filiere);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.id_structure)) {
            param = param.append("id_structure", parameters.id_structure);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.type_candidat)) {
            param = param.append("type_candidat", parameters.type_candidat);
        }
        if (Util_fonction.checkIfNoTEmpty(parameters.type_frais)) {
            param = param.append("type_frais", parameters.type_frais);
        }

        return this.httpClient.get<any>(`${url}`, {params: param});
    }

    /* */
}
