import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BASE_URL } from "../util/REST_URL";

const url = BASE_URL + "/informations";

@Injectable({
    providedIn: "root",
})
export class PagesInformationDataService {
    constructor(private http: HttpClient) {}

    // //CAMPUS -VISITEUR INFO - ACTUALITE
    // public SavePageInformations(body){
    //     console.log("body service ==> ", body);
    //     return this.http.post<any>(url, body);
    // }
    //
    // public UpdatePageInformations(body){
    //     console.log("body ==> ", body);
    //     return this.http.put<any>(url, body);
    // }

    public getPageInformations(type) {
        let param = new HttpParams();
        param = param.append("search", "type=" + type);
        return this.http.get<any>(url, { params: param });
    }
    public getPageActuInformations(pathS) {
        let param = new HttpParams();
        param = param.append("search", pathS);
        return this.http.get<any>(url, { params: param });
    }

    // public deleteInfo(id){
    //     return this.http.delete<any>(url+"/"+id);
    //
    // }
}
