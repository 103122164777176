<header class="header-area">
    <!-- Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="top-header-social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-play-store"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-apple"></i
                            ></a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8 col-md-12">
                    <ul class="top-header-contact-info">
                        <li><i class="bx bx-map"></i>{{ adresseUniv }}</li>
                        <li>
                            <i class="bx bx-mail-send"></i
                            ><a href="mailto:usttbinfo@gmail.com">{{
                                emailUniv
                            }}</a>
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i
                            ><a href="tel:+22320290407">{{ contactUniv }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="qovid-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="{{ logoUniv }}" class="logo" />
                    </a>

                    <button
                        class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div
                        class="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav">
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Accueil</a
                                >
                            </li>

                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="null"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >A propos <i class="bx bx-chevron-down"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            routerLink="/structure"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Faculté & Institut</a
                                        >
                                    </li>
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            routerLink="/bibliotheque"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Bibliothèque</a
                                        >
                                    </li>
                                </ul>
                            </li>

                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/infos-visiteurs"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Infos visiteurs</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/annonces"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Actualités</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/formations"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Formations</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/forum"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Forum</a
                                >
                            </li>
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/campus"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Campus</a
                                >
                            </li>
                            <!-- <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/bibliotheque"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Bibliothèque</a
                                >
                            </li> -->
                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a
                                    routerLink="/contact"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Contact</a
                                >
                            </li>

                            <li
                                class="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                <a style="cursor: pointer" class="nav-link">
                                    <i
                                        class="bx bxs-user-circle text-success"
                                        *ngIf="currentUserData"
                                    ></i>
                                    <i
                                        class="bx bxs-user-circle text-primary"
                                        *ngIf="!currentUserData"
                                    ></i>
                                </a>
                                <!--           routerLink="/profil"                             <a routerLink="/universite" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Actualité <i class='bx bx-chevron-down'></i></a>-->
                                <ul
                                    class="dropdown-menu"
                                    *ngIf="!currentUserData"
                                >
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            style="cursor: pointer"
                                            (click)="goToProfil()"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Connection au forum</a
                                        >
                                    </li>
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            style="cursor: pointer"
                                            (click)="navigateToAdmin()"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                        >
                                            Mon espace
                                        </a>
                                    </li>
                                </ul>
                                <ul
                                    class="dropdown-menu"
                                    *ngIf="currentUserData"
                                >
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            style="cursor: pointer"
                                            (click)="logOut()"
                                        >
                                            <span class="small"
                                                ><i
                                                    class="fa fa-power-off text-danger"
                                                ></i
                                            ></span>
                                            Deconnexion</a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="others-option d-flex align-items-center">
                            <div class="option-item">
                                <div class="cart-btn">
                                    <!--                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">-->
                                    <!--                                        <a style="cursor: pointer" class="nav-link" (click)="goToProfil()">-->
                                    <!--                                            <i class='bx bxs-user-x text-danger' *ngIf="currentUserData"></i>-->
                                    <!--                                            <i class='bx bxs-user-circle text-primary' *ngIf="!currentUserData"></i>-->
                                    <!--                                        </a>-->
                                    <!--&lt;!&ndash;                                        <a routerLink="/universite" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Actualité <i class='bx bx-chevron-down'></i></a>&ndash;&gt;-->
                                    <!--                                        <ul class="dropdown-menu">-->
                                    <!--                                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/universite" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Evènement</a></li>-->
                                    <!--                                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/universite" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Agenda</a></li>-->
                                    <!--                                        </ul>-->
                                    <!--                                    </li>-->
                                </div>
                            </div>

                            <div class="option-item">
                                <div class="search-btn-box">
                                    <i class="search-btn bx bx-search-alt"></i>
                                </div>
                            </div>

                            <div class="option-item">
                                <button
                                    class="default-btn nav-item"
                                    style="
                                        background-color: #006e90;
                                        border: #006e90;
                                        display: block;
                                        visibility: hidden;
                                    "
                                    (click)="StructureChoose()"
                                >
                                    <i class="flaticon-open-book"></i>Admission
                                </button>
                                <ul class="dropdown-menu">
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            routerLink="/universite"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Evènement</a
                                        >
                                    </li>
                                    <li
                                        class="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse.show"
                                    >
                                        <a
                                            routerLink="/universite"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Agenda</a
                                        >
                                    </li>
                                </ul>
                                <!--                                <button mat-button [matMenuTriggerFor]="menu">Menu</button>-->
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
</header>

<!-- SELECTION DE LA STRUCTURE-->
<div
    class="modal fade"
    id="StructureListModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title font-weight-bold" id="exampleModalLabel">
                    Selectionnez votre structure d'admission
                </h4>
                <button type="button" mat-button data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="" *ngIf="CheckIsNotEmpty(ListDesStructures)">
                    <table class="table table-hover">
                        <tbody>
                            <tr
                                *ngFor="let structure of ListDesStructures"
                                class="mt-2 shadow-sm"
                            >
                                <td>
                                    <img
                                        src="{{ _http }}{{ structure.logo }}"
                                        alt=""
                                        style="height: 100px; width: 100px"
                                    />
                                </td>
                                <td>
                                    <h3>{{ structure.sigle }}</h3>
                                    <h6 class="text-uppercase">
                                        {{ structure.nom }}
                                    </h6>
                                </td>

                                <td>
                                    <button
                                        class="btn btn-outline-info"
                                        (click)="
                                            GoToInscriptionEvenements(structure)
                                        "
                                    >
                                        <span class="material-icons"
                                            >radio_button_checked</span
                                        >
                                        SLECTIONNER
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                >
                    Annuler
                </button>
            </div>
        </div>
    </div>
</div>

<!-- QUESTION DE REPRENDRE-->
<div
    class="modal fade"
    id="BackChooseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4
                    class="modal-title font-weight-bold"
                    id="exampleModalLabel2"
                >
                    Selectionnez votre structure d'admission
                </h4>
                <button type="button" mat-button data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </div>
            <div class="modal-body">
                <h3 class="text-center">
                    Souhaitez-vous changer de structure d'adminsion ?
                </h3>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                >
                    NON
                </button>
                |
                <button
                    type="button"
                    class="btn btn-info"
                    data-dismiss="modal"
                    (click)="backToStructureChoose()"
                >
                    OUI, CHANGER DE STRUCTURE !
                </button>
            </div>
        </div>
    </div>
</div>

<!--
<div class="swiper">
  
  <div class="swiper-wrapper">
  
    <div class="swiper-slide">Slide 1</div>
    <div class="swiper-slide">Slide 2</div>
    <div class="swiper-slide">Slide 3</div>
  </div>
  
  <div class="swiper-pagination"></div>

  
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>

  
  <div class="swiper-scrollbar"></div>
</div>
-->

<!-- Auth Modal -->
<div
    class="modal fade"
    id="AuthModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4
                    class="modal-title font-weight-bold"
                    id="exampleModalLabel3"
                >
                    Authentification
                </h4>
                <button type="button" mat-button data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </div>
            <div class="modal-body">
                <!--                <h3 class="text-center">Souhaitez-vous changer de structure d'adminsion ?</h3>-->
                <div>
                    <div class="mt-2">
                        <label for="username"
                            >Nom d'utilisateur
                            <span class="text-danger">*</span></label
                        >
                        <input
                            type="text"
                            id="username"
                            [(ngModel)]="username"
                            class="form-control"
                            placeholder="nom d'utilisateur ..."
                        />
                    </div>
                    <div class="mt-2">
                        <label for="password"
                            >Mot de passe
                            <span class="text-danger">*</span></label
                        >
                        <input
                            type="password"
                            id="password"
                            [(ngModel)]="password"
                            class="form-control"
                            placeholder="nom d'utilisateur ..."
                        />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <Button
                    (click)="SubmitAuthForm()"
                    class="btn btn-primary"
                    [disabled]="username === null || password === null"
                >
                    Valider
                </Button>
            </div>
        </div>
    </div>
</div>
