import { Component, OnInit } from '@angular/core';
import { DiscussionService } from '../../shared/service/discussion.service';
import { AuthService } from '../../shared/service/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from '../../shared/service/comment.service'; // Assurez-vous que l'importation est correcte
import { GetCommentService } from '../../shared/service/get-comment.service';
import {formatDate} from "../../shared/util/globalFunction";
import {SpinnerService} from "../../shared/service/spinner.service"; // Assurez-vous que l'importation est correcte



@Component({
    selector: 'app-forum',
    templateUrl: './forum.component.html',
    styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {
    sujet: any;
    Subjects: any[] = [];
    commentContent: any;
    user = JSON.parse(sessionStorage.getItem('user'));
    comments: any[];


    commentBoxVisible = false;

    form: FormGroup; // Ajout du formulaire

    constructor(
        private discussionService: DiscussionService,
        private router: Router,
        private authService: AuthService,
        private spinner: SpinnerService,
        private commentService: CommentService,
        private getComment:GetCommentService,
        private fb: FormBuilder
    ) {}

    toggleCommentBox() {
        this.commentBoxVisible = !this.commentBoxVisible;
    }

    ngOnInit(): void {
        this.initForm(); // Appel de la méthode initForm

        const parameters = {
            annee: '2022 - 2023',
            date: null,
            idStructure: '2',
            page: '0',
            size: '100',
            status: 'OPEN'
        };

        this.discussionService.getPostWithCriteria(parameters).subscribe((data: any) => {
            this.sujet = data;
            this.Subjects = data.content;
            console.log(data);
        });
        if (!this.authService.isUserLoggedIn()) {
            // Affichez la boîte modale de connexion seulement si l'utilisateur n'est pas connecté
            this.authService.isOpen = true;
        }
    }

    // Méthode pour initialiser le formulaire
    initForm() {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    login() {
        console.log('form data', this.form.value);
        this.spinner.show('Connexion ....');
        this.authService.authenticate(this.form.value).subscribe(
            (response: any) => {
                console.log('login response', response);
                this.spinner.hide();
                this.authService.sigIn(response);
            },
            error => {
                console.log('login error', error);
                this.spinner.hide();
            },
        );
    }


    addComment() {
        const commentData = {
            content: this.commentContent,
            date: formatDate() ,//new Date().getDate(),//.toISOString(),
            post: this.currentSubjectId,
            user: this.user.users.id

        };
        console.log(this.commentContent);
        console.log("---> ", commentData);

        this.commentService.addComment(commentData).subscribe(
            (response: any) => {
                console.log('Comment added successfully', response);
                this.commentContent = '';
                this.reloadDiscussion();
                this.getCommentaires(this.currentSubjectId);

            },
            error => {
                console.error('Error adding comment', error);
            }
        );
    }
    currentSubjectId:any;
    showComment(subjectId){
        this.currentSubjectId=subjectId;
        this.commentContent = null;
        this.getCommentaires(subjectId);

    }
    verifiedUserConnect(){
        return !this.authService.isUserLoggedIn();
    }
    reloadDiscussion() {
    }
    closeLoginModal() {
        this.authService.isOpen = false;
    }

    getCommentaires(SubjectId:number){
        this.getComment.getComment({idPost:SubjectId}).subscribe(
            (data:any)=>{

                this.comments = data.content;
                console.log('commentaires recupereers avec sucess')
            },
            error => {
                console.error('comment non recupéres', error);
            }
        );



  //  getPostComments(){
        // this.commentService.addComment(commentData).subscribe(
        //     (response: any) => {
        //         this.commentContent = '';
        //         this.reloadDiscussion();
        //         this.getPostComments();
        //     },
        //     error => {
        //         console.error('Error adding comment', error);
        //     }
        // );

    }
}
