<app-header-four></app-header-four>

<div class="banner-wrapper banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-wrapper-content">
                    <h1>Most People Infected With The COVID-19</h1>
                    <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others, Save Humankind!</p>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                        <a href="https://www.youtube.com/watch?v=iVo5j9mZq7g" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="outbreak-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Statistics</span>
            <h2>COVID-19 Statistics</h2>
        </div>

        <div class="outbreak-content">
            <div class="outbreak-box-list">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon5.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="196">00</h3>
                            <p>Total Country</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon6.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="375498">00</h3>
                            <p>Confirmed Cases</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon7.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="16362">00</h3>
                            <p>Deaths</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon8.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="109102">00</h3>
                            <p>Recovered</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/banner-map.png" alt="image">
        </div>
    </div>
</section>

<section class="about-area-two bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img4.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">About Covid-19</span>
                        <h2>About Coronavirus Disease</h2>
                        <h6>We can support student forum 24/7 for national and international patient.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Know the Symptoms of the Coronavirus(Covid-19) and Inform Others, Follow the Safety Steps Instructed by World Health Organization (WHO).</p>
                        <div class="features-text">
                            <h5><i class='bx bx-planet'></i>Most People Infected With The COVID-19 Virus</h5>
                            <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">Covid-19</span>
                        <h2>Know More About Covid-19</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        <ul class="features-list">
                            <li><span><i class='bx bx-check-double'></i> Person to Person</span></li>
                            <li><span><i class='bx bx-check-double'></i> Infected Person Coughs</span></li>
                            <li><span><i class='bx bx-check-double'></i> Close Contact Another</span></li>
                            <li><span><i class='bx bx-check-double'></i> Wash Your Hands</span></li>
                            <li><span><i class='bx bx-check-double'></i> Avoid Close Contact</span></li>
                            <li><span><i class='bx bx-check-double'></i> Stay at Home</span></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img5.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img6.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">Protect Yourself</span>
                        <h2>How To Protect Yourself</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Know the Symptoms of the Coronavirus(Covid-19) and Inform Others, Follow the Safety Steps Instructed by World Health Organization (WHO).</p>
                        <div class="features-text">
                            <h5><i class='bx bx-planet'></i>Most People Infected With The COVID-19 Virus</h5>
                            <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area bg-f9f9f9 pb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="prevention-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to protect yourself</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Should Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon1.png" alt="image">
                            </div>
                            <h3>Wash Your Hand For 20 Sec</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon2.png" alt="image">
                            </div>
                            <h3>Wear Mask All The Time</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon3.png" alt="image">
                            </div>
                            <h3>Avoid Contact With Animals</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon4.png" alt="image">
                            </div>
                            <h3>Always Cover Your Sneeze</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Shouldn’t Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon5.png" alt="image">
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon6.png" alt="image">
                            </div>
                            <h3>Don't Handshake</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon7.png" alt="image">
                            </div>
                            <h3>Don't Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon8.png" alt="image">
                            </div>
                            <h3>Avoid Travel</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="prevention-shape1"><img src="assets/img/prevention-shape1.png" alt="image"></div>
    <div class="prevention-shape2"><img src="assets/img/prevention-shape2.png" alt="image"></div>
    <div class="prevention-shape3"><img src="assets/img/prevention-shape3.png" alt="image"></div>
    <div class="prevention-shape4"><img src="assets/img/prevention-shape4.png" alt="image"></div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Covid</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Adjust</span>
                        <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Coronavirus</span>
                        <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>