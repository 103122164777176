<app-header></app-header>
<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</section>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <h3>Contactez nous</h3>
                    <p>Nous nous ferons un plaisir de répondre à toutes vos questions. Envoyez-nous simplement un
                        email ou appeler nous directement ci contre les informations nécessaire.
                    </p>
                    <br/>
                    <div class="container">
                        <div>
                            <iframe
                                style=""
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3893.133345247064!2d-8.005440225689942!3d12.63925588764641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe51cd364ea17b21%3A0x7872dbe4c7ecf8a0!2sRectoract%20USSGB!5e0!3m2!1sfr!2sml!4v1694252501944!5m2!1sfr!2sml"
                                width="680" height="480" style="border:0;"
                                allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">

                            </iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <h3>Adresse</h3>
                    <ul class="contact-list">
                        <li><i class='bx bx-map'></i>Emplacement : <a href="#">{{adresseUniv}}</a></li>
                        <li><i class='bx bx-phone-call'></i>Appelez-nous : <a href="tel:+22320220865">(+223) 20 22 08 65</a></li>
                        <li><i class='bx bx-envelope'></i>Envoyez-nous un email : <a href="mailto:ussgb@yahoo.fr">{{emailUniv}}</a></li>
                        <li><i class='bx bx-microphone'></i> BP : <a href="tel:{{contactUniv}}"> {{bpUniv}}</a></li>
                    </ul>

                    <h3>Horaires d'ouvertures:</h3>
                    <ul class="opening-hours">
                        <li><span>Lundi : </span> 8h30 - 16h30</li>
                        <li><span>Mardi : </span> 8h30 - 16h30</li>
                        <li><span>Mercredi : </span> 8h30 - 16h30</li>
                        <li><span>Jeudi : </span> 8h30 - 16h30</li>
                        <li><span>Vendredi : </span> 8h30 - 16h30</li>
                        <li><span>Samedi : </span> Fermé</li>
                        <li><span>Dimanche : </span> Fermé</li>
                    </ul>

                    <h3>Suivez-nous : </h3>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-play-store'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-apple'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="emergency-contact-area pb-100">-->
<!--    <div class="container">-->
<!--        <div class="emergency-contact-inner">-->
<!--            <div class="row align-items-center">-->
<!--                <div class="col-lg-8 col-md-12">-->
<!--                    <div class="btn-box">-->
<!--                        <a class="default-btn" routerLink="/"><i class="flaticon-open-book"></i> Request Appointment</a>-->
<!--                        <a class="default-btn" routerLink="/"><i class="flaticon-doctor"></i> Meet The Doctor</a>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-lg-4 col-md-12">-->
<!--                    <div class="contact-cta">-->
<!--                        <a href="tel:+44587154756">-->
<!--                            <i class="flaticon-call"></i>-->
<!--                            <span>En cas d'urgence, contactez le </span>-->
<!--                            (+223) 20 29 04 07-->
<!--                        </a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
