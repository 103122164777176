import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Util_fonction} from '../model/util_fonction';


const url = BASE_URL+"/informations";

@Injectable({
    providedIn: 'root'
})
export class CampusService {

    constructor(private httpClient: HttpClient) {
    }

    /* */
    public getInformations(parameters: any) {
        let param = new HttpParams();
        if (Util_fonction.checkIfNoTEmpty(parameters.search)) {
            param = param.append("search", parameters.search);
        }

        if (Util_fonction.checkIfNoTEmpty(parameters.type)) {
            param = param.append("type", parameters.type);
        }
        return this.httpClient.get<any>(`${url}`, { params: param });
    }

    /* */
}
