import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { HeaderComponent } from "./components/layouts/header/header.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { HomeFiveComponent } from "./components/pages/home-five/home-five.component";
import { HeaderTwoComponent } from "./components/layouts/header-two/header-two.component";
import { HeaderThreeComponent } from "./components/layouts/header-three/header-three.component";
import { HeaderFourComponent } from "./components/layouts/header-four/header-four.component";
import { OnepageNavbarComponent } from "./components/layouts/onepage-navbar/onepage-navbar.component";
import { HomeSixComponent } from "./components/pages/home-six/home-six.component";
import { UniversiteComponent } from "./components/pages/universite/universite.component";
import { AboutTwoComponent } from "./components/pages/about-two/about-two.component";
import { FormationComponent } from "./components/pages/formation/formation.component";
import { DoctorsComponent } from "./components/pages/doctors/doctors.component";
import { AppointmentComponent } from "./components/pages/appointment/appointment.component";
import { CampusComponent } from "./components/pages/campus/campus.component";
import { OutbreakComponent } from "./components/pages/outbreak/outbreak.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { BlogOneComponent } from "./components/pages/blog-one/blog-one.component";
import { BlogTwoComponent } from "./components/pages/blog-two/blog-two.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ProductsComponent } from "./components/pages/products/products.component";
import { ProfilComponent } from "./components/pages/profil/profil.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { ProductsDetailsComponent } from "./components/pages/products-details/products-details.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { LiveTrackerComponent } from "./components/pages/live-tracker/live-tracker.component";
import { ConnexionComponent } from "./components/layouts/connexion/connexion.component";
import { InformationComponent } from "./components/pages/information/information.component";
import { BasicAuthInterceptorService } from "./components/shared/service/basic-auth-interceptor.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { AichaSpinnerComponent } from "./components/shared/component/aicha-spinner/aicha-spinner.component";
import {
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
} from "@angular/material";
import { ForumComponent } from "./components/pages/forum/forum.component";
import { AnoncesComponent } from "./components/pages/_portail/anonces/anonces.component";
import { FormationsComponent } from "./components/pages/_portail/formations/formations.component";
import { AboutComponent } from "./components/pages/_portail/about/about.component";
import { InfoVisiteurComponent } from "./components/pages/_portail/info-visiteur/info-visiteur.component";
import { ContactComponent } from "./components/pages/_portail/contact/contact.component";
import { StructuresComponent } from "./components/pages/_portail/about/structures/structures.component";
import { RouterModule } from "@angular/router";
import { BibliothequeComponent } from './components/pages/_portail/bibliotheque/bibliotheque.component';
//import {    InscriptionsEvenementsComponent } from './components/pages/_visiteurs/inscriptions-evenements/inscriptions-evenements.component';
//import { ProfilageEtVerificationComponent } from './components/pages/_visiteurs/profilage-et-verification/profilage-et-verification.component';
//import {CandidatInfosComponent} from './components/pages/_visiteurs/_Formulaires_Inscriptions/candidat-infos/candidat-infos.component';
//import { InscriptionInfosComponent } from './components/pages/_visiteurs/_Formulaires_Inscriptions/inscription-infos/inscription-infos.component';
// import AppRoutingModule from './app-routing.module'

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        HomeComponent,
        HeaderComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        HeaderTwoComponent,
        HeaderThreeComponent,
        HeaderFourComponent,
        OnepageNavbarComponent,
        HomeSixComponent,
        UniversiteComponent,
        AboutTwoComponent,
        FormationComponent,
        DoctorsComponent,
        AppointmentComponent,
        CampusComponent,
        OutbreakComponent,
        FaqComponent,
        BlogOneComponent,
        BlogTwoComponent,
        BlogDetailsComponent,
        ProductsComponent,
        ProfilComponent,
        CheckoutComponent,
        ProductsDetailsComponent,
        ErrorComponent,
        ComingSoonComponent,
        LiveTrackerComponent,
        ConnexionComponent,
        InformationComponent,
        AichaSpinnerComponent,
        ForumComponent,
        AnoncesComponent,
        FormationsComponent,
        AboutComponent,
        InfoVisiteurComponent,
        ContactComponent,
        StructuresComponent,
        BibliothequeComponent,
        //InscriptionsEvenementsComponent,
        //ProfilageEtVerificationComponent,
        //CandidatInfosComponent,
        //InscriptionInfosComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTabsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatMenuModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
