import { Component, OnInit } from '@angular/core';
import {_HTTP} from "../../../shared/model/CONSTANTES";
import {StructuresService} from "../../../shared/service/structures.service";
import {FilieresOptionsService} from "../../../shared/service/filieres-options.service";
import {logger} from "codelyzer/util/logger";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-formations',
    templateUrl: './formations.component.html',
    styleUrls: ['./formations.component.scss']
})
export class FormationsComponent implements OnInit {
    _http = _HTTP;
    Structures;
    selected: string;
    logoUniv = environment.logoUniv;

    constructor(
        private structureService : StructuresService,
        private filiereOptionService: FilieresOptionsService
    ) { }

    ngOnInit(): void {
        this.GetStructures();
    }

    GetStructures() {
        this.structureService.getStuctures().subscribe(
            res => {
                this.Structures = res.filter(s => s.type !== 'RECTORAT');
            }
        );
    }

    selectedStructureData: any;
    selectStructure(structure){
        this.selected = structure.id.toString();
        this.selectedStructureData = structure;
        this.GetStructureFilieres(structure.id);
    }

    // SelectedFiliereList
    // GetStructureFilieres(structureId){
    //   this.filiereOptionService.getStructureFilieres(structureId).subscribe(
    //       response => {
    //           console.log("response ==> ", response);
    //           this.SelectedFiliereList = response;
    //   });
    // }

    SelectedFiliereList
    GetStructureFilieres(structureId){
        this.filiereOptionService.getStructure_Options(structureId).subscribe(
            response => {
                console.log("response ==> ", response);
                const groupResponse = this.groupBy(response, "nom");
                this.SelectedFiliereList = Object.entries(groupResponse);
                console.log("this.SelectedFiliereList ==> ", this.SelectedFiliereList);
            });
    }

    groupBy(xs, key) {
        return xs.reduce(function(rv, x, i) {
            (rv[x["filiere"][key]] = rv[x["filiere"][key]] || []).push(x);
            return rv;
        }, {});
    };
}
