import { Injectable } from '@angular/core';
import { Util_fonction } from '../model/util_fonction';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_URL } from '../util/REST_URL';


const urlLivre = BASE_URL + "/ouvrages";

const urlAuteur = BASE_URL + "/auteurs";
const urlEditeur = BASE_URL + "/editeurs";
const urlGenre = BASE_URL + "/genresLivres";


@Injectable({
    providedIn: "root",
})
export class OuvragesService {
    constructor(private http: HttpClient) {}

    GetAllOuvrage(body) {
        let param = new HttpParams();
        if (Util_fonction.checkIfNoTEmpty(body.id_auteur)) {
            param = param.append("id_auteur", body.id_auteur);
        }
        if (Util_fonction.checkIfNoTEmpty(body.id_editeur)) {
            param = param.append("id_editeur", body.id_editeur);
        }
        if (Util_fonction.checkIfNoTEmpty(body.id_genre)) {
            param = param.append("id_genre", body.id_genre);
        }
        if (Util_fonction.checkIfNoTEmpty(body.id_structure)) {
            param = param.append("id_structure", body.id_structure);
        }
        if (Util_fonction.checkIfNoTEmpty(body.id_user)) {
            param = param.append("id_user", body.id_user);
        }
        if (Util_fonction.checkIfNoTEmpty(body.titre)) {
            param = param.append("titre", body.titre);
        }
        if (Util_fonction.checkIfNoTEmpty(body.type)) {
            param = param.append("type", body.type);
        }
        return this.http.get<any>(urlLivre, { params: param });
    }

    list_Genre() {
        return this.http.get<any>(urlGenre);
    }
    list_Editeur() {
        return this.http.get<any>(urlEditeur);
    }
    list_auteurs() {
        return this.http.get<any>(urlAuteur);
    }
}
