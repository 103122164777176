import { Injectable } from '@angular/core';
import {BASE_URL} from '../util/REST_URL';
import {HttpClient} from '@angular/common/http';


// const url = BASE_URL+"/structures";
const url = BASE_URL+"/structures/list";
const API_URL2 = BASE_URL+`/structures`;
const anneeUrl = BASE_URL+"/annees";

@Injectable({
  providedIn: 'root'
})
export class StructuresService {

    constructor(private httpClient: HttpClient){}

    public getStuctures(){
        return this.httpClient.get<any>(url);
    }

    public getStucture(id){
        return this.httpClient.get<any>(`${url}/${id}`);
    }

    public getLogo(filename){
        return this.httpClient.get(`${url}/logo/${filename}`);
    }

    public addStructure(structure){
        console.log(structure);
        return this.httpClient.post(API_URL2,structure);
    }
    public updateStructure(structure) {
        return this.httpClient.put(`${API_URL2}/${structure.id}`, structure);
    }
    public deleteStructure(id) {
        return this.httpClient.delete(`${API_URL2}/${id}`);
    }
    /** **************************************************************************
     * Service de Gestion de L'année de la structure
     * ===========================================================================
     * **/

    // public getAnnees() {
    //   return this.httpClient.get(anneeUrl);
    // }

    public getAnneeByID(id){
        return this.httpClient.get(`${anneeUrl}/${id}`);
    }

    public getStuctureAnnees(structureID) {
        return this.httpClient.get<any>(`${anneeUrl}/structure/${structureID}`);
    }

    public getListComptable(structureID) {
        return this.httpClient.get(`${BASE_URL}/versements/listComptable/${structureID}`);
    }

    public getStructureCurrentAnnee(structureID) {
        return this.httpClient.get<any>(`${anneeUrl}/structure/encours/${structureID}`);
    }

    public addAnnee(annee){
        // console.log(annee);
        return this.httpClient.post<any>(anneeUrl,annee);
    }
    public updateAnnee(annee) {
        return this.httpClient.put<any>(`${anneeUrl}/${annee.id}`, annee);
    }
    public deleteAnnee(id) {
        return this.httpClient.delete(`${anneeUrl}/${id}`);
    }

}
